.titulo-nosotros{
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  width: 97%;
  line-height: 1.44;
  letter-spacing: 4px;
  text-align: center;
  color: #000000;
  font-family: CocogooseProRegular;
}
.titulo-nosotrosH{
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  width: 97%;
  line-height: 1.44;
  letter-spacing: 4px;
  text-align: initial;
  color: #000000;
  font-family: CocogooseProRegular;
}
.texto-nosotros {
  font-family: AvenirBook;
  font-size: 21px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.55;
  letter-spacing: normal;
  color: #000;
}

.nosotros-container{
  width: 100%;
  margin-top: 100px;
}

.nosotros-container-sombra{
  width: 100%;
  box-shadow: 0 7px 47px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  padding: 0px 14% 0 11%;
}

.linea-nosotros{
  position: absolute;
  z-index: 1;
  top: -2%;
  left: -2.5%;
  height: 623px;
  width: 623px;
}

.titulo-principal{
  font-family: CocogooseProRegular;
  font-size: 42px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: -1px;
  color: #000000;
  margin: 150px 0 17px;
}

.año-nosotros{
  font-family: CocogooseProRegular;
  font-size: 51px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.73;
  letter-spacing: -1px;
  text-align: center;
  color: #1b2437;
  margin: 15px 0 20px;
}
.descripcion-nosotros {
  font-family: AvenirBook;
  font-size: 19px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: center;
  color: #1b2437;
}

.animation-section {
  width: 100%;
  display: flex; 
  justify-content: center;
  align-items: center;
}

.animation-title{
  font-family: CocogooseLight;
  font-size: 21px;
  line-height: 1.44;
  letter-spacing: 4px;
  color: #000000;
  text-align: center;
}

.contrainer-animation{
  position: relative;
  width: 650px;
  background-image: url("./animacion/linea.png");
  background-repeat: no-repeat;
  background-size: 90% 90%;
  height: 650px;
  margin-left:6%;
  margin-bottom: 100px;
}

.img-product-desk{
    display: block;
  }

  .img-product-mob{
    display: none;
  }

@media screen and (max-width: 687px), (max-height: 325px) {

  .contrainer-animation{
    width: 300px;
    background-image: url("./animacion/linea.png");
    background-repeat: no-repeat;
    background-size: 90% 90%;
    height: 300px;
  }
  .titulo-principal{    
    font-size: 27px;
    margin: 27px 0 17px;
   
  }
  .titulo-nosotros {
    font-family: CocogooseProRegular;
    font-size: 1.2em !important;
    letter-spacing: 2px;
  }
  .titulo-nosotrosH {
    font-family: CocogooseProRegular;
    font-size: 1.2em !important;
    letter-spacing: 2px;
  }
  .texto-nosotros {
    font-size: 15px;
  }
  .nosotros-container{
    width:100%;
    padding:19px;
    margin-top:10px
  }
  .nosotros-container-sombra{
    width: 100%;
    padding: 19px;
  }
  

  .linea-nosotros {
    position: absolute;
    z-index: 1;
    top: 0%;
    left: -4%;
    height: 274px;
    width: 98%;
  }

  .animation-title{
    font-family: CocogooseProRegular;
    font-size: 13px;
    text-align: center;
    letter-spacing: 3px;
  }
  .año-nosotros {
    font-size: 30px;
    margin: 5px 0 10px;
  }
  .descripcion-nosotros {
    font-size: 10px;
  }
  .img-product-desk{
    display: none;
  }

  .img-product-mob{
    display: block;
  }
}