.MAYONESA-CON-CHIPOTL {
  
  cursor:pointer;
  font-family: CocogooseProRegular;
  font-size: 25px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.24;
  letter-spacing: 4px;
  color: #732626;
}

td {
  font-family: AvenirBook;
}
.container-regresar{
  margin-left: 5%;
}
.REGRESAR-A-MAYONESAS {
  cursor: pointer;
 
margin: 0px;
  font-family: CocogooseProRegular;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 3.29;
  letter-spacing: normal;
  color: #000000;
}
.container-regresar-mov{
  margin-left: 5%;
  margin-top: 10px;
}
.collapsible {
  width: 100%;
  cursor: pointer;
  font-family: CocogooseProRegular;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 3.29;
  letter-spacing: 2.19px;
  color: #000000;
  
}
.flecha-anterior{
  max-width: 100%;
  height: auto;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 10%;
  position: absolute;
  cursor:pointer;
}
.product-hover{
  top: auto !important;
  bottom: 18%
}
.producto-div-container{
  transform: translate(50%, 0);
  max-width: 28vw;
  float: right;
  height: 500px;
}
.producto{
  object-fit: contain;
  width: 100%;
  height: 100%;
  /*max-width: 20vw;*/

}
.producto-fondo{
  position: absolute;
  right: 0;
  max-height: 400px; 
  width: 100%;
  object-fit: cover;
}
.content {
  padding: 0 18px;
  display: none;
  overflow: hidden;
  background-color: #f1f1f1;
}
.PRESENTACIONES {
  width: 140px;
  height: 16px;
  font-family: CocogooseProRegular;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 1.8px;
  color: #000000;
}
.detalle-desk{
  display: block
}
.detalle-desk p{
  font-family: AvenirBook;
}
.detalle-mobile p{
  font-family: AvenirBook;
}
.detalle-mobile{
  display: none
}
@media screen and (max-width: 687px), (max-height: 325px) {
  .detalle-desk{
    display: none
  }
  .detalle-mobile{
    display: block
  }
}
@media screen and (min-width: 1600px) {
  .producto-div-container{
    max-width: 61%;
  }
}
