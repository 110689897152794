.container-promociones{
    margin-top: 6px;
    padding: 30px;
    padding-top: 0px;
}
.btn-promo-juego{
    padding-left: 150px;
    padding-right: 150px;
    font-family:AvenirBlack;
    margin-top: 10px;
   border: 3px solid #d41246;
    border-radius: 15px;
    transition: 1.0s;
    font-size: 2vw;

    background: #d41246;
    color: #FFFFFF;
    margin-top: 19px;
    margin: 2.9% 10px 10px;
}
.btn-promo-juego:hover {
    background: transparent;
    border: 3px solid  #d41246 !important;
    color: #d41246;
    transition: 1.0s;
    cursor: pointer;
}
.btn-promo-tokens{
    padding-left: 150px;
    padding-right: 150px;
    font-family:AvenirBlack;
    margin-top: 10px;
   border: 3px solid #35BEAD;
    border-radius: 15px;
    transition: 1.0s;
    font-size: 2vw;

    background: #35BEAD;
    color: #FFFFFF;
    margin-top: 19px;
    margin: 2.9% 10px 10px;
    
}
.btn-promo-tokens:hover {
    background: transparent;
    border: 3px solid #35BEAD !important;
    color: #35BEAD;
    transition: 1.0s;
    cursor: pointer;
}
.container-promo-btn{
    margin-top: 0px;
    margin-bottom: 40px;
}
.btn-promo{
    padding-left: 40px;
    padding-right: 40px;
    font-family:AvenirBlack;
    margin-top: 10px;
   border: 3px solid #d41246;
    border-radius: 15px;
    transition: 1.0s;
    font-size: 2vw;

    background: #d41246;
    color: #FFFFFF !important;    
}

.btn-promo:hover {
    background: transparent;
    border: 3px solid #d41246 !important;
    color: #d41246 !important;
    transition: 1.0s;
    cursor: pointer;
}

    


.swiper-promociones .swiper-button-next{
  
    background-image: url("./img/der.svg");
}
.swiper-promociones .swiper-button-prev{
    background-image: url("./img/isq.svg");
}
.swiper-promociones .swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
    /* bottom: 129px !important; */
    

}
.promoBanner{
    height: 88vh;
}
.collapsible-promociones {
    font-family: CocogooseProRegular;
    font-size: 42px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    
    color: #000;
}
.title-promo > label{
        font-size: 42px;
    }
    .video-juego{
        height:600px;
        width:100%;
    }
@media screen and (max-width: 687px), (max-height: 325px) {
    .promoBanner{
        height: auto;
    }
    .video-juego{
        height:175px;
        width:100%;
    }
    .btn-promo-juego{
        padding-left: 40px;
        padding-right: 40px;
        font-family:AvenirBlack;
        margin-top: 10px;
       border: 3px solid #d41246;
        border-radius: 15px;
        transition: 1.0s;
        font-size: 1rem;
    
        background: #d41246;
        color: #FFFFFF;
        margin-top: 19px;
        margin: 2.9% 10px 10px;
    }
    
    .btn-promo-tokens{
        padding-left: 40px;
        padding-right: 40px;
        font-family:AvenirBlack;
        margin-top: 10px;
       border: 3px solid #35BEAD;
        border-radius: 15px;
        transition: 1.0s;
        font-size: 1rem;
    
        background: #35BEAD;
        color: #FFFFFF;
        /* margin-top: 13px; */
        margin: 13px 10px 10px;
        
    }

    .btn-promo{
        padding-left: 40px;
        padding-right: 40px;
        font-family:AvenirBlack;
        margin-top: 10px;
       border: 3px solid #d41246;
        border-radius: 15px;
        transition: 1.0s;
        font-size: 1rem;
    
        background: #d41246;
        color: #FFFFFF;
        /* margin-top: 13px; */
        margin: 13px 10px 10px;
        
    }
    
    .btn-promo:hover {
        background: transparent;
        border: 3px solid #d41246 !important;
        color: #d41246;
        transition: 1.0s;
        cursor: pointer;
    }
    .swiper-promociones .swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
        bottom: 100px !important;
    
    }
    .container-promociones{
        margin-top: 0px;
        padding: 10px
    }
.title-promo > label{
        font-size: 22px !important;
    }
    .collapsible-promociones {
        font-size: 20px;
    }
}