.CONTACTO {
    width: 347px;
    height: 31px;
    font-family: CocogooseProRegular;
    font-size: 42px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.74;
    letter-spacing: 6.72px;
    color: #000000;
    margin-left: 5%;
}
.imput-contacto{
    border-radius: .25rem;
    border: 1px solid  gray;
    padding: 5px;
}
.imput-contacto:focus{
    border-color: #fed767;
    border: 3px solid #fed767;
    outline: none !important;
    
}
.Se-envio-tu-mensaje {
    margin-top:50px;
    font-family: CocogooseProRegular;
    font-size: 20px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.55;
    letter-spacing: 1px;
    text-align: center;
    color: #000000;
  }
  .form-contacto{
    width: 40% ;
    margin-top: 50px ;
    display: flex; 
    justify-content: center ;
    align-items: center; 
    flex-direction: column;
}
.contacto-container{
        width: 100%; 
        margin-top: 50px ;
        padding: 35px
}

.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-radius: 5px;
  border: solid 3px #d41245;
}


/*.MuiInputLabel-outlined {
  font-family: AvenirBook !important;
}*/

  @media screen and (max-width: 687px), (max-height: 325px) {
      .form-contacto{
            width: 100% ;
           
      }
      .contacto-container{
        width: 100%; 
        margin-top: 10px ;
        padding: 20px
}

  }