.tam-closeIcon{
    width: 17px;
    height: 16px;
    position: absolute;
    top: 25px;
    right: 30px;
    z-index: 2;
}

.metismenu {
    background: white;
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    font-weight: 900;
    overflow: hidden;
}

.metismenu>.metismenu-container>.metismenu-item>.metismenu-link {
    line-height: 2.5em
}

.metismenu > .metismenu-container > .metismenu-item > .metismenu-container.visible > .metismenu-item .metismenu-item > .metismenu-link{
    color: #bababa;
    font-size: 14px;
}

.metismenu > .metismenu-container > .metismenu-item > .metismenu-container.visible > .metismenu-item .metismenu-item > .metismenu-link.active{
    background: #eeeeee;
}


.metismenu > .metismenu-container > .metismenu-item > .metismenu-container.visible > .metismenu-item{
    border-bottom: 2px solid rgba(0, 0, 0, .04);
    font-size: 16px;

}

.metismenu>.metismenu-container>.metismenu-item>.metismenu-link .metismenu-state-icon {
    line-height: 2.5em
}

.metismenu::after {
    content: " ";
    pointer-events: none;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0
}

.metismenu-container, .metismenu-item {
    margin: 0;
    padding: 0
}



.metismenu-container {
    list-style: none
}

.metismenu-container .metismenu-container {

    
    transition: padding .3s;
    -webkit-transition: padding .3s;
    background: rgba(255, 255, 255, .05);
}

.metismenu-container .metismenu-container .metismenu-item>.metismenu-link {
    height: 0;
    overflow: hidden
}

.metismenu-container .metismenu-container .metismenu-link {
    padding-left: 1em
}

.metismenu-container .metismenu-container .metismenu-container .metismenu-link {
    padding-left: 1.6em
}

.metismenu-container.visible {
    padding: .5em 0
}

.metismenu-container.visible>.metismenu-item>.metismenu-link {
    height: 2.5em
}

.metismenu-link {
    color: black;
    transition: height .3s, color .3s, background-color .3s;
    -webkit-transition: height .3s, color .3s, background-color .3s;
    display: block;
    line-height: 2.5em;
    text-decoration: none
}

.metismenu-link:hover {
    background: #ededed;
    color: #000000
}

.metismenu-link.active {
    background: #ededed;
    color: #000000
}

.metismenu-link.has-active-child {
    color: #000000
}

I.metismenu-icon {
    text-align: center;
    width: 3em
}

I.metismenu-state-icon {
    margin-right: 30px;
    transition: transform .3s;
    -webkit-transition: transform .3s;
    float: right;
    line-height: 2.5em;
    text-align: center;
    width: 1em
}

I.metismenu-state-icon.rotate-minus-90 {
    transform: rotate(-180deg);
    -webkit-transform: rotate(-180deg)
}

.bm-item {
     display: block;
}

.bm-menu {
    background: #ffffff;
    padding: 4.5em 0em 2.5em;
}

.fa {
    display: inline-block;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.fa-caret-left:before {
    max-width: 16px;
    content: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE5LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgdmlld0JveD0iMCAwIDQ5MS45OTYgNDkxLjk5NiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDkxLjk5NiA0OTEuOTk2OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DQo8Zz4NCgk8Zz4NCgkJPHBhdGggZD0iTTQ4NC4xMzIsMTI0Ljk4NmwtMTYuMTE2LTE2LjIyOGMtNS4wNzItNS4wNjgtMTEuODItNy44Ni0xOS4wMzItNy44NmMtNy4yMDgsMC0xMy45NjQsMi43OTItMTkuMDM2LDcuODZsLTE4My44NCwxODMuODQ4DQoJCQlMNjIuMDU2LDEwOC41NTRjLTUuMDY0LTUuMDY4LTExLjgyLTcuODU2LTE5LjAyOC03Ljg1NnMtMTMuOTY4LDIuNzg4LTE5LjAzNiw3Ljg1NmwtMTYuMTIsMTYuMTI4DQoJCQljLTEwLjQ5NiwxMC40ODgtMTAuNDk2LDI3LjU3MiwwLDM4LjA2bDIxOS4xMzYsMjE5LjkyNGM1LjA2NCw1LjA2NCwxMS44MTIsOC42MzIsMTkuMDg0LDguNjMyaDAuMDg0DQoJCQljNy4yMTIsMCwxMy45Ni0zLjU3MiwxOS4wMjQtOC42MzJsMjE4LjkzMi0yMTkuMzI4YzUuMDcyLTUuMDY0LDcuODU2LTEyLjAxNiw3Ljg2NC0xOS4yMjQNCgkJCUM0OTEuOTk2LDEzNi45MDIsNDg5LjIwNCwxMzAuMDQ2LDQ4NC4xMzIsMTI0Ljk4NnoiLz4NCgk8L2c+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8L3N2Zz4NCg==");
}

