.spin{
    -webkit-animation: spin 1.5s linear ;
    animation: spin 1.5s linear ;
  }
  @-webkit-keyframes spin {
  	0%{ -webkit-transform: rotate(0deg); tranform: rotate(0deg);}
	100%{ -webkit-transform: rotate(360deg); tranform: rotate(360deg);}
}

@keyframes spin {
	0%{ -webkit-transform: rotate(0deg); transform: rotate(0deg);}
  100%{ -webkit-transform: rotate(360deg); transform: rotate(360deg);}
}
