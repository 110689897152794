.container-collapse{
    position: relative;
    border-top: 3px solid #fed86b;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    font-family: AvenirBook !important;
    font-size: 18px !important;
}