.sombra{
    -webkit-box-shadow: 1px 2px 22px 7px rgba(0,0,0,0.3); 
    box-shadow: 1px 2px 22px 7px rgba(0,0,0,0.3);
  }
  .info-te-sorprendemos{
    z-index: 10;
    transform: translate(-50%, -50%);
    top: 46%;
    right:  -17%;
    padding: 10px;
    position: absolute; 
}