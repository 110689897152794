.mermelada{
  position: relative;
    background: #ffffff;
  width: 100%;
  height: 200px;
  position: relative;
  display: flex;
    justify-content: flex-end;
  overflow: hidden;

}


.footerlinks{
  text-align: left;
 
}

.contenedor{
  width: 100%;
  padding-right: 2%;
  margin-right: 12%;
  margin-left: auto;
}

.footer {
  flex-direction: column;
    position: relative;;
    left: 0;
    bottom: 0;
    color:#ffffff;
    width: 100%;
    background: #d41246;
    text-align: center;
    display: flex;
    padding: 10px;
    height: 200px;
  }
  .footer-mobile{
    display: none; 
    width: 100% ;
    justify-content: space-between;
  }
  .footer-desk{
    display: flex; 
    width: 100% ;
    justify-content: space-between;
  }
  .icon-footer{
    height: auto;
    width: 20%;
    cursor: pointer;
    padding-right: 10%;
    padding-top: 10%
  }
  .social-footer{
    display: flex;
    justify-content: space-evenly; 
    /* align-items:center;  */
    width: 50%;
    left: 35%;    

  }

  .mermeladaM{
    display: none;
  }
 

  @media screen and (max-width: 687px), (max-height: 325px) {
   
    .footer{
      flex-direction: column;
      padding: 2% !important;
      width: auto;
      height: auto;
    
      z-index: 20;
    }
    .social-footer{
      display: flex;
      justify-content: space-evenly; 
      align-items:center; 
      width: 100%;
    }
    .footer-mobile{
      display: flex;
      height:auto;
    }
    .footer-desk{
      display: none
    }
    .mermeladaM{
      height: 150px;
    }
    .icon-footer{
      height: 50%;
      width: 30%;
      justify-content: space-evenly;
      padding-right: 0%; 
      padding-bottom: 0%;
      padding-top: 0%
    }

    
   
  }