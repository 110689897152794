.button-plato{
  background: #ffd766 !important;
  border-radius: 15px !important;
  border: 0px solid !important;
  font-family: CocogooseProRegular !important;
  font-size: 1vw;
  padding: 7px;
  padding-left: 15px;
  padding-right: 15px

}
.scene{

  z-index: 60;

}
.swiper-home .swiper-container{
height: 575px !important;
}

.layer {
  pointer-events: auto !important;
}
.swiper-slide-next > .button-plato-swiper-mv{
    background: #ffd766 !important;
    border-radius: 15px !important;
    border: 0px solid !important;
    font-family: CocogooseProRegular !important;
    padding: 7px;
    padding-left: 15px;
    padding-right: 15px;

}

.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
    background: #d41246 ;
    border: 3px solid #d41246 !important;
    width: 15px !important;
    height: 15px !important;
}
.swiper-pagination-bullet-active {
  border: 3px solid #d41246 !important;
  width: 15px !important;
}
.swiper-scrollbar {
    border-radius: 10px;
    position: relative;
    -ms-touch-action: none;
    background: transparent;
}
.swiper-scrollbar-drag {
    background: #000000;
}

.icon-navigation{
    background: #000000;
}

.swiper-button-prev{
    background-image: url("../Resource/iconos/arrow-slide-izq.svg");
    background-repeat: no-repeat;

}
.swiper-button-next{
    background-image: url("../Resource/iconos/arrow-slide-der.svg");
    background-repeat: no-repeat;  
    right: 2%;
}
.button-not-focus{
    background: transparent;
    font-size: 13px;
    border: 0px;
    font-family: CocogooseProRegular !important;
}
.plato{
    width: 424px;
    height: 424px;
    background-image: url("./images/plato.png");
    background-repeat: no-repeat;
}
.CocogooseProRegular{
    font-family: CocogooseProRegular;
}
.PROMOCIONES {
    width: 151px;
    height: 46px;
    font-family: Avenir;
    font-size: 16px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.88;
    letter-spacing: 2.5px;
    color: #ffffff;
  }
.PARTICIPA-Y-GANA-CON {
    width: 471px;
    height: 90px;
    font-family: CocogooseProRegular;
    font-size: 42px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: normal;
    color: #ffffff;
  }

.plato-info{
    font-family: AvenirBook;
    font-size: 15px;
    background: rgb(255,255,255);
    background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(255,249,249,0.01724439775910369) 56%);
    padding: 90px;
    pointer-events: none;
    position: absolute;
    top: 35%;
    left: 50%;
    z-index: 1;
    transform: translate(-50%, -50%);
    text-align: center;
}
.linea{
    background-image: url("./images/line-3.svg");
    background-repeat: no-repeat;
    position: absolute;
    top: 9%;
    left: 50%;
    z-index: 1;
    transform: translate(-50%, -50%);
    text-align: center;
    pointer-events: none;
}

.opacity-isq{
    background: rgb(255,255,255);
    background: linear-gradient(90deg, rgba(255,255,255,1) 16%,transparent);
    z-index: 2;
    height: 45px;
    width: 145px;
    position: absolute;
    left: 11%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.opacity-der{
    background: rgb(255,255,255);
    background: linear-gradient(90deg, transparent, rgba(255,255,255,1) 76%);
    z-index: 2;
    height: 45px;
    width: 250px;
    position: absolute;
    left: 78%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.opacity-izq-mov{
    
    background-image: linear-gradient(to right, rgba(255,255,255,1), rgba(255,255,255,0));;
    z-index: 2;
    height: 100px;
    width: 145px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
}
.opacity-der-mov{
    background-image: linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,1));
    z-index: 2;
    height: 100px;
    width: 145px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0,-50%);
}
.content-opacity{
  position: absolute;
  width: 100%;
  left: 0;
  height: 100px;
}
.swiperbuttonmovil{
  min-height: 100px;
}


.swiper-scrollbar{
    height: 10px !important;
}
.hover-content{
    position: relative;
    display: flex;
    padding: 20px;      
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 13px;
}
.image-plato{
  cursor: pointer;
}
.image-plato:active{
  cursor: grabbing;
}

.hover-platillo{
    position: absolute;
    text-align: center;
    width: 100%;
    height: 100%;
    top: 0px;
    display: flex;
   justify-content: center;
   align-items: center;
    -webkit-transition: .5s;
    transition: .5s;
    background:transparent;
    opacity: 0.8;
    
}
.hover-platillo:hover{
    background:#ffffff !important;
    transition: 0.5s;
   
}
.info{
    font-family: CocogooseProRegular;
    width: 41%;
    z-index: 10;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 78%;
    position: absolute;
}

.infos{
  font-family: CocogooseProRegular;
  width: 41%;
  z-index: 10;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 58%;
  position: absolute;
}

.promoFut{
  font-family: CocogooseProRegular;
  width: 41%;
  z-index: 10;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 78%;
  position: absolute;

}
.custom-class{
    position: absolute
}
.btn-carousel{
    padding-left: 40px;
    padding-right: 40px;
    font-family:AvenirBlack;
    margin-top: 10px;
   border: 3px solid #d41246;
    border-radius: 15px;
    transition: 1.0s;

    background: #d41246;
    color: #FFFFFF !important;
}
.btn-carousel:hover{
    background: transparent;
    border: 3px solid #d41246;
    color: #d41246 !important;
    transition: 1.0s;
    cursor: pointer;
  
}
.btn-promociones{
    padding-left: 40px;
    padding-right: 40px;
    font-family:Avenir;
  margin-top: 10px;
    background: transparent;
    border: 3px solid #ffffff;
    color: #ffffff;
    border-radius: 15px;
    transition: 1.0s
}

.btn-promociones:hover{
  background: #d41246;
  color: #FFFFFF;
  transition: 1.0s
  
}

.hvr-sweep-to-right:hover {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
}


.rotate-class{
    transition: 0.2s;
}
.rotate-class:hover{
    transition: 0.5s;
transform: rotate(20deg)
}
.scale-class{
    transition: 0.2s;
   /* animation:shake 2s alternate infinite;*/
}
.animation-move{
    animation:shake 6s alternate infinite;
}
.animation-up-downd{
   
    animation:go 3s alternate infinite;
}
.animation-up-downd-prod-especia{
    animation:gopro 3s alternate infinite;
}
.animation-up-downd-prod-tes{
  animation:goprotes 4s alternate infinite;
}
.latidos{
    animation:latidos 2s alternate infinite;
}
.animation-left-right{
    animation: caritaleftright 3s linear infinite;
}
.scale-class:hover{
    transition: 0.5s;
    transform: scale(1.2);
    
  
}
.recetas-anim{
    transition: 0.2s;
  
}
.recetas-anim:hover{
    transition: 0.5s;
    transform: scale(1.2) 
}
.home-mobile{
    display: none;
  
  }
  .home-desk{
    display: block;
  }

  @keyframes caritaleftright {
    0%   {
      margin-left: 30%;
    }
    25%  {
      margin-left: 25%;
      transform:rotate(-15deg)
    }
    50%  {
      margin-left: 30%;
      transform:rotate(0deg)
    }
    75%  {
      margin-left: 35%;
      transform:rotate(15deg)
    }
    100% {
      margin-left: 30%;
    }
}
@keyframes gopro{
    0%{ top:50%;}
    50%{ top:55%;}
  }
  @keyframes latidos{
    30%{ transform:  scale(1.2);}
    
  }
  @keyframes goprotes{
    0%{ top:50%;}
    60%{ top:55%;}
  }
  @keyframes latidos{
    30%{ transform:  scale(1.2);}
    
  }

@keyframes go{
  0%{ margin-bottom:0px;}
  50%{ margin-bottom:15px;}
}
  @keyframes shake {
    10%, 100% {
      transform: translate3d(-1px, 0, 0);
    }
    
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%, 50%, 70% {
      transform: translate3d(-1px, 0, 0);
    }
  
    40%, 60% {
      transform: translate3d(2px, 0, 0);
    }
   
}
  
  @media screen and (max-width: 687px), (max-height: 325px) {
    .home-mobile{
      display: block;
    }
    .home-desk{
      display: none;
    }
    .button-not-focus{
        font-size: 9px;
    }
    .plato-info{
        padding: 0px
    }
    .info-te-sorprendemos{
      z-index: 10;
      transform: translate(-44%, -50%);
      top: 25% !important;
      left: 48%;
      padding: 10px;
      position: absolute; 
  }
    .sombra{
      -webkit-box-shadow: 1px 2px 22px 7px rgba(0,0,0,0.3); 
      box-shadow: 1px 2px 22px 7px rgba(0,0,0,0.3);
    }
    .col-5 {
      -ms-flex: 0 0 41.666667%;
      flex: 0 0 41.666667%;
      max-width: 90.666667% !important;
    }
    .contain-swip{
      padding-top:10px;
      width: 100% !important;
     
    }
    .opacity-isq{
      background: rgb(255,255,255);
      background: linear-gradient(90deg, rgba(255,255,255,1) 16%,transparent);
      z-index: 2;
      height: 60px;
      width: 145px;
      position: absolute;
      left: 16%;
      top: 30%;
      transform: translate(-50%, -50%);
    }

    .opacity-der{
        background: rgb(255,255,255);
        background: linear-gradient(90deg, transparent, rgba(255,255,255,1) 76%);
        z-index: 2;
        height: 60px;
        width: 145px;
        position: absolute;
        left: 84%;
        top: 30%;
        transform: translate(-50%, -50%);
    }

    .btnBanner-homeMobile-verRecetas{
      font-size: 13px;
      padding-left: 12px;
      padding-right: 12px;
    }

    .btnBanner-homeMobile-promoFut{
      font-size: 13px;
      padding-left: 12px;
      padding-right: 12px;
    }

    .btnBanner-homeMobile-verProductos{
      margin-left: 15px;
      font-size: 13px;
      padding-left: 12px;
      padding-right: 12px;
    }
  }