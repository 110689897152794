.ANTOJITOS {
  cursor: pointer;
    font-family: AvenirBook;
    font-size: 14px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 3.29;
    letter-spacing: 2.19px;
    color: #d41245;
  }
  .prod-util-prin{
    background: white;
    position: relative;
    width: 100%;
    height: 240px;
  }
  .div-color-prod{
    position: absolute;
    height: 143px;
    width: 100%;
    bottom: 0;
    background: #b8e986;

  }
  .productos-swiper > .swiper-container{
    background: none !important;

  }
  .productos-swiper{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 50%;
  }
  .list-preparacion li:nth-child(even){
    background: gainsboro;
  }
  .ROLLITO-DE-VEGETALES {
   
    font-family: CocogooseProRegular;
    font-size: 26px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: 1px;
    color: #000000;
  }
  .Sirve-este-excelente {
 
    font-family: AvenirBook;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: normal;
    color: #000000;
  }
  .INGREDIENTES {
    
    font-family: CocogooseProRegular;
    font-size: 16px;
   
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    color: #417505;
  }
  .PARA-ESTA-RECETA-NEC {
    position: absolute;
    width: 272px;
    bottom: 25px;
    left: 23px;
    font-family: CocogooseProRegular;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: normal;
    color: #000000;
  }
.detalle-receta-desk{
    display: block
}

.detalle-receta-mobile{
    display: none      
}
.donde-comprar {
  font-family: CocogooseProRegular;
  font-size: 26px;
  padding: 50px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  line-height: 1.38;
  letter-spacing: 1px;
  color: #000000;
}
.container-tiendas{
  width: 100%;
  display: flex;
  flex-wrap:wrap
}
.container-logo{
  flex: 0 0 50%;
  max-width: 50%;
}
@media screen and (max-width: 687px), (max-height: 325px) {
  .container-logo{
    flex: 0 0 100%;
    max-width: 100%;
  }
  .container-tiendas{
    width: 100%;
    display: flex;
    flex-wrap:wrap;
    flex-direction: column;
    
  }
    .detalle-receta-desk{
        display: none
    }
    .donde-comprar {
      font-size: 15px;
      padding: 15px
    }
    
    .detalle-receta-mobile{
        display: block      
    }



    .ANTOJITOS {
 
    text-align: justify
      }
      .ROLLITO-DE-VEGETALES {
       
     font-size: 20px
      }
      .Sirve-este-excelente {
     
        text-align: justify
      }
      .INGREDIENTES {
      text-align: justify
      }
     
}