
.lds-dual-ring {

  display: contents;
  width: 80px;
  height: 80px;
}
.fb-post{
  min-height: 526px !important;
  border: 0px !important;
  width: 85%;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  top: -3px;
  left: -3px;
  position: absolute;
  width: 94%;
  height: 94%;
  margin: 8px;
  border-radius: 50%;
  border: 5px solid #fff;
  border-color: red transparent red transparent;
  -webkit-animation: lds-dual-ring 4s linear infinite;
  animation: lds-dual-ring 4s linear infinite;

}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.fb_iframe_widget iframe{
  width: 100% !important;

  height: 100% !important;
}
.fb_iframe_widget_fluid span {
  width: 100% !important;
  height: 507px !important;
}

.hvr-float-shadow{
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}
.hvr-float-shadow:hover{
  transform: translateY(-5px);
}

.container-tips {
    position: relative;
    cursor: pointer;
  margin-top: 2%;
  }
  .container-tip{
    width: 47%;
    margin-top: 1%;
    /* padding: 0% 3% 0%; */
  }
  
  .image {
    display: block;
    width: 100%;
    height: auto;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color:#000000ac;
  }
  
  .container-tips:hover .overlay {
    opacity: 0.9;
  }
  
  .text {
    color: white;
    font-size: 1em;
    position: absolute;
    padding: 2%;
    top: 50%;
    width: 100%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .facebook-mobile{
    display: none
  }
  .facebook-desk{
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .banner-desk{
    display: flex !important;
    max-height: 350px;
  }
  .banner-mobile{
    display: none !important
  }
  .container-menu-blog{
    
    font-family: AvenirBlack ;
    font-size: 1.6em;
     width: 90%;
     margin-top: 5%;
  }
  .image-menu{
    max-width: 100%; 
    height: auto; 
    z-index: 1;
    border-radius: 50%;
  }
  .container-menu-item{
    text-align: center;
    font-size: 0.6em;
    font-family: "CocogooseLight";
    width: 25%;
    display: flex; 
    flex-direction: column;
     align-items: center; 
     justify-content: center;
      position: relative;
      color: #707070
  }
  .title-blog{
    font-family: AvenirBlack;
     font-size: 1.6em;
    margin-top:4% 
  }
  .redes-text{
    font-family: CocogooseProRegular;
     font-size: 3em
  }
  .insta-img{
    max-width: 100%;
     height: auto
  }
  .title-redes{
     color: #CD0F41; 
     font-family: CocogooseProRegular; 
     margin-left: 1%;
      margin-bottom: 0px;
      font-size: 2em,
  }
  .insta-container{
    width: 30%;
    margin: 0 1% 1%;

  }
  .container-foto{
  width: 66%; 
  position: relative;
  display:flex;
  justify-content:center;
  align-items:center;
  padding:5%
  }
  .container-facebook{
    display: flex; 
    width: 95%; 
    position: relative; 
    flex-wrap: wrap; 
    justify-content: center 
  }
  .isq-blog{
    max-width: 100%; 
    height: auto; 
    top: 43%; 
    left: 1%; 
    position: absolute;
    cursor: pointer; 
    z-index: 2 
  }
  .der-blog{
    max-width: 100%; 
    height: auto; 
    top: 43%; 
    right: 1%; 
    position: absolute;
    cursor: pointer; 
    z-index: 2 
  }
  .swiper-blog-mobile{
    display: none;
  }
  .swiper-blog{
    display: block
  }
  .imagen-banner-blog{
    width: 100%;
    height: 370px ;
  }
  .container-notas{
     display: flex;position: relative; flex-wrap: wrap; justify-content: space-evenly; width: 90%;margin-top:2%
  }
 
  
  @media screen and (max-width: 687px), (max-height: 325px) {
    .container-notas{
      display: flex;position: relative; flex-wrap: wrap; justify-content: space-evenly; width: 90%;margin-top:5%
   }
    .imagen-banner-blog{
      max-width: 100%;
      height: 100px ;
    }
    .text{
      font-size:9px
    }
    .swiper-blog-mobile{
      display: block;
    }
    .swiper-blog{
      display: none
    }
    .isq-blog{
      max-width: 7%; 
      
    }
    .der-blog{
      max-width: 7%; 
  
    }
    .container-facebook{
      margin-top: 4%
    }
    .container-foto{
      width: 90%; 
    }
    .insta-container{
      width: 48%
    }
    .title-redes{
      font-size: 14px,
    }
    .insta-img{
      max-width: 13%;
    }
    .redes-text{
 
       font-size: 24px
    }
    .title-blog{
    
       font-size: 18px;
   
    }
    .container-tip{
      width: 100%;
      margin-top: 1%;
      padding: 0%;
    }
    .container-menu-item{
      font-size: 8px;
    }
    .image-menu{
      padding: 6%
    }
    .container-menu-blog{
       width: 88%
    }
    .lds-dual-ring:after {
      content: " ";
      display: block;
      top: -7px;
      left: -7px;
      position: absolute;
      width: 97%;
      height: 97%;
      margin: 8px;
      border-radius: 50%;
      border: 3px solid #fff;
      border-color: red transparent red transparent;
      -webkit-animation: lds-dual-ring 4s linear infinite;
      animation: lds-dual-ring 4s linear infinite;
    
    }
  
    .que-se-antoja{
      display: none
    }
    .banner-desk{
      display: none !important;
      max-height: 350px;
    }
    .banner-mobile{
      display: flex !important;
    }
    .facebook-mobile{
      display: flex;
      justify-content: center;
      width: 100%;
    }
    .facebook-desk{
      display: none
    }
  }