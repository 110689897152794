body {
  margin: 0;
  padding: 0;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: overlay;
 
  overflow-x: hidden;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin-bottom: 0.5rem;
  font-weight: bold;
  line-height: 1.2;
}
#root{
  max-width: 1440px;
  margin: auto;
}
pre img{
  width: 15vw !important;
  margin: 1px 20px 1px 20px;
}
pre p{
 margin-bottom: 5px;
}
pre{
white-space: inherit !important;
font-size: 18px !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@font-face {
  /*negrita*/
  font-family:CocogooseProRegular;
  src: url(/static/media/Cocogoose-Pro-Regular-OTF.aea828ef.otf);
}

@font-face {
  /*delgadita*/
  font-family:CocogooseUltraLight;
  src: url(/static/media/Cocogoose-Pro-Ultralight.970dcca1.ttf);
}

@font-face {
  /*normal*/
  font-family:CocogooseLight;
  src: url(/static/media/Cocogoose-Pro-Light.b4e46f03.ttf);
}

@font-face {
  font-family:SignPainter;
  src: url(/static/media/SignPainter-HouseScript.95b0e138.otf);
}
@font-face {
  font-family: AvenirBlack;
  src: url(/static/media/Avenir-Black.d751de38.otf);
  
}
@font-face {
  font-family: AvenirBook;
  src: url(/static/media/AvenirLTStd-Book.5b7bb748.otf);
  
}
@font-face {
  font-family: AvenirNextLTProMedium;
  src: url(/static/media/AvenirNextLTPro-MediumCnIt.7e8fbf1d.otf);
  
}

@font-face {
  font-family: AvenirMedium;
  src: url(/static/media/AvenirMedium.519023f4.ttf);
}

@font-face {
  font-family: RobotoMedium;
  src: url(/static/media/roboto-medium.894a2ede.ttf);
}
.App{

    min-height:100vh;
    min-width: 100%;
  
    position: relative;

}

.custom-class{
position: absolute;
z-index: 50;
}
.content::-webkit-scrollbar:vertical { display: none}
::-webkit-scrollbar {
    width: 12px;
    height: 12px;
}

button{
    outline: none !important
}

.content::-webkit-scrollbar-track:horizontal {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    background-color: #ffffff;
    border-radius: 10px;
}
.content::-webkit-scrollbar-thumb{
background: rgba(200,200,200,1)  !important;
}
body::-webkit-scrollbar-thumb{
    background: rgba(200,200,200,1)  !important;
    }
 
.content::-webkit-scrollbar-thumb:horizontal {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
    background-color: #ffffff;
}
 
body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    border-radius: 10px;
    background-color: #ffffff;
}

body::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
    background-color: #ffffff;
}
div::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
    background-color:#ff0000;
}
div::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    border-radius: 10px;
    background-color:#ffffff;
}
@media screen and (max-width: 687px), (max-height: 325px) {

  
}
.hvr-float-shadow:hover {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
}
.hvr-float-shadow {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
  
    position: relative;
    transition-duration: 0.3s;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
}
.card-container{
    margin-top: 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    cursor: pointer;
}

.button-plato{
  background: #ffd766 !important;
  border-radius: 15px !important;
  border: 0px solid !important;
  font-family: CocogooseProRegular !important;
  font-size: 1vw;
  padding: 7px;
  padding-left: 15px;
  padding-right: 15px

}
.scene{

  z-index: 60;

}
.swiper-home .swiper-container{
height: 575px !important;
}

.layer {
  pointer-events: auto !important;
}
.swiper-slide-next > .button-plato-swiper-mv{
    background: #ffd766 !important;
    border-radius: 15px !important;
    border: 0px solid !important;
    font-family: CocogooseProRegular !important;
    padding: 7px;
    padding-left: 15px;
    padding-right: 15px;

}

.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
    background: #d41246 ;
    border: 3px solid #d41246 !important;
    width: 15px !important;
    height: 15px !important;
}
.swiper-pagination-bullet-active {
  border: 3px solid #d41246 !important;
  width: 15px !important;
}
.swiper-scrollbar {
    border-radius: 10px;
    position: relative;
    -ms-touch-action: none;
    background: transparent;
}
.swiper-scrollbar-drag {
    background: #000000;
}

.icon-navigation{
    background: #000000;
}

.swiper-button-prev{
    background-image: url(/static/media/arrow-slide-izq.bd14234c.svg);
    background-repeat: no-repeat;

}
.swiper-button-next{
    background-image: url(/static/media/arrow-slide-der.ad13aca3.svg);
    background-repeat: no-repeat;  
    right: 2%;
}
.button-not-focus{
    background: transparent;
    font-size: 13px;
    border: 0px;
    font-family: CocogooseProRegular !important;
}
.plato{
    width: 424px;
    height: 424px;
    background-image: url(/static/media/plato.27076ac7.png);
    background-repeat: no-repeat;
}
.CocogooseProRegular{
    font-family: CocogooseProRegular;
}
.PROMOCIONES {
    width: 151px;
    height: 46px;
    font-family: Avenir;
    font-size: 16px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.88;
    letter-spacing: 2.5px;
    color: #ffffff;
  }
.PARTICIPA-Y-GANA-CON {
    width: 471px;
    height: 90px;
    font-family: CocogooseProRegular;
    font-size: 42px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: normal;
    color: #ffffff;
  }

.plato-info{
    font-family: AvenirBook;
    font-size: 15px;
    background: rgb(255,255,255);
    background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(255,249,249,0.01724439775910369) 56%);
    padding: 90px;
    pointer-events: none;
    position: absolute;
    top: 35%;
    left: 50%;
    z-index: 1;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    text-align: center;
}
.linea{
    background-image: url(/static/media/line-3.5c6dcc86.svg);
    background-repeat: no-repeat;
    position: absolute;
    top: 9%;
    left: 50%;
    z-index: 1;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    text-align: center;
    pointer-events: none;
}

.opacity-isq{
    background: rgb(255,255,255);
    background: linear-gradient(90deg, rgba(255,255,255,1) 16%,transparent);
    z-index: 2;
    height: 45px;
    width: 145px;
    position: absolute;
    left: 11%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}
.opacity-der{
    background: rgb(255,255,255);
    background: linear-gradient(90deg, transparent, rgba(255,255,255,1) 76%);
    z-index: 2;
    height: 45px;
    width: 250px;
    position: absolute;
    left: 78%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}
.opacity-izq-mov{
    
    background-image: linear-gradient(to right, rgba(255,255,255,1), rgba(255,255,255,0));;
    z-index: 2;
    height: 100px;
    width: 145px;
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
}
.opacity-der-mov{
    background-image: linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,1));
    z-index: 2;
    height: 100px;
    width: 145px;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translate(0,-50%);
            transform: translate(0,-50%);
}
.content-opacity{
  position: absolute;
  width: 100%;
  left: 0;
  height: 100px;
}
.swiperbuttonmovil{
  min-height: 100px;
}


.swiper-scrollbar{
    height: 10px !important;
}
.hover-content{
    position: relative;
    display: -webkit-flex;
    display: flex;
    padding: 20px;      
    -webkit-flex-direction: column;      
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    font-size: 13px;
}
.image-plato{
  cursor: pointer;
}
.image-plato:active{
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.hover-platillo{
    position: absolute;
    text-align: center;
    width: 100%;
    height: 100%;
    top: 0px;
    display: -webkit-flex;
    display: flex;
   -webkit-justify-content: center;
           justify-content: center;
   -webkit-align-items: center;
           align-items: center;
    transition: .5s;
    background:transparent;
    opacity: 0.8;
    
}
.hover-platillo:hover{
    background:#ffffff !important;
    transition: 0.5s;
   
}
.info{
    font-family: CocogooseProRegular;
    width: 41%;
    z-index: 10;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    top: 50%;
    left: 78%;
    position: absolute;
}

.infos{
  font-family: CocogooseProRegular;
  width: 41%;
  z-index: 10;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  top: 50%;
  left: 58%;
  position: absolute;
}

.promoFut{
  font-family: CocogooseProRegular;
  width: 41%;
  z-index: 10;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  top: 50%;
  left: 78%;
  position: absolute;

}
.custom-class{
    position: absolute
}
.btn-carousel{
    padding-left: 40px;
    padding-right: 40px;
    font-family:AvenirBlack;
    margin-top: 10px;
   border: 3px solid #d41246;
    border-radius: 15px;
    transition: 1.0s;

    background: #d41246;
    color: #FFFFFF !important;
}
.btn-carousel:hover{
    background: transparent;
    border: 3px solid #d41246;
    color: #d41246 !important;
    transition: 1.0s;
    cursor: pointer;
  
}
.btn-promociones{
    padding-left: 40px;
    padding-right: 40px;
    font-family:Avenir;
  margin-top: 10px;
    background: transparent;
    border: 3px solid #ffffff;
    color: #ffffff;
    border-radius: 15px;
    transition: 1.0s
}

.btn-promociones:hover{
  background: #d41246;
  color: #FFFFFF;
  transition: 1.0s
  
}

.hvr-sweep-to-right:hover {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    transition-property: color;
    transition-duration: 0.3s;
}


.rotate-class{
    transition: 0.2s;
}
.rotate-class:hover{
    transition: 0.5s;
-webkit-transform: rotate(20deg);
        transform: rotate(20deg)
}
.scale-class{
    transition: 0.2s;
   /* animation:shake 2s alternate infinite;*/
}
.animation-move{
    -webkit-animation:shake 6s alternate infinite;
            animation:shake 6s alternate infinite;
}
.animation-up-downd{
   
    -webkit-animation:go 3s alternate infinite;
   
            animation:go 3s alternate infinite;
}
.animation-up-downd-prod-especia{
    -webkit-animation:gopro 3s alternate infinite;
            animation:gopro 3s alternate infinite;
}
.animation-up-downd-prod-tes{
  -webkit-animation:goprotes 4s alternate infinite;
          animation:goprotes 4s alternate infinite;
}
.latidos{
    -webkit-animation:latidos 2s alternate infinite;
            animation:latidos 2s alternate infinite;
}
.animation-left-right{
    -webkit-animation: caritaleftright 3s linear infinite;
            animation: caritaleftright 3s linear infinite;
}
.scale-class:hover{
    transition: 0.5s;
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    
  
}
.recetas-anim{
    transition: 0.2s;
  
}
.recetas-anim:hover{
    transition: 0.5s;
    -webkit-transform: scale(1.2);
            transform: scale(1.2) 
}
.home-mobile{
    display: none;
  
  }
  .home-desk{
    display: block;
  }

  @-webkit-keyframes caritaleftright {
    0%   {
      margin-left: 30%;
    }
    25%  {
      margin-left: 25%;
      -webkit-transform:rotate(-15deg);
              transform:rotate(-15deg)
    }
    50%  {
      margin-left: 30%;
      -webkit-transform:rotate(0deg);
              transform:rotate(0deg)
    }
    75%  {
      margin-left: 35%;
      -webkit-transform:rotate(15deg);
              transform:rotate(15deg)
    }
    100% {
      margin-left: 30%;
    }
}

  @keyframes caritaleftright {
    0%   {
      margin-left: 30%;
    }
    25%  {
      margin-left: 25%;
      -webkit-transform:rotate(-15deg);
              transform:rotate(-15deg)
    }
    50%  {
      margin-left: 30%;
      -webkit-transform:rotate(0deg);
              transform:rotate(0deg)
    }
    75%  {
      margin-left: 35%;
      -webkit-transform:rotate(15deg);
              transform:rotate(15deg)
    }
    100% {
      margin-left: 30%;
    }
}
@-webkit-keyframes gopro{
    0%{ top:50%;}
    50%{ top:55%;}
  }
@keyframes gopro{
    0%{ top:50%;}
    50%{ top:55%;}
  }
  @-webkit-keyframes latidos{
    30%{ -webkit-transform:  scale(1.2); transform:  scale(1.2);}
    
  }
  @keyframes latidos{
    30%{ -webkit-transform:  scale(1.2); transform:  scale(1.2);}
    
  }
  @-webkit-keyframes goprotes{
    0%{ top:50%;}
    60%{ top:55%;}
  }
  @keyframes goprotes{
    0%{ top:50%;}
    60%{ top:55%;}
  }
  @keyframes latidos{
    30%{ -webkit-transform:  scale(1.2); transform:  scale(1.2);}
    
  }

@-webkit-keyframes go{
  0%{ margin-bottom:0px;}
  50%{ margin-bottom:15px;}
}

@keyframes go{
  0%{ margin-bottom:0px;}
  50%{ margin-bottom:15px;}
}
  @-webkit-keyframes shake {
    10%, 100% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }
    
    20%, 80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }
  
    30%, 50%, 70% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }
  
    40%, 60% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }
   
}
  @keyframes shake {
    10%, 100% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }
    
    20%, 80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }
  
    30%, 50%, 70% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }
  
    40%, 60% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }
   
}
  
  @media screen and (max-width: 687px), (max-height: 325px) {
    .home-mobile{
      display: block;
    }
    .home-desk{
      display: none;
    }
    .button-not-focus{
        font-size: 9px;
    }
    .plato-info{
        padding: 0px
    }
    .info-te-sorprendemos{
      z-index: 10;
      -webkit-transform: translate(-44%, -50%);
              transform: translate(-44%, -50%);
      top: 25% !important;
      left: 48%;
      padding: 10px;
      position: absolute; 
  }
    .sombra{ 
      box-shadow: 1px 2px 22px 7px rgba(0,0,0,0.3);
    }
    .col-5 {
      -webkit-flex: 0 0 41.666667%;
              flex: 0 0 41.666667%;
      max-width: 90.666667% !important;
    }
    .contain-swip{
      padding-top:10px;
      width: 100% !important;
     
    }
    .opacity-isq{
      background: rgb(255,255,255);
      background: linear-gradient(90deg, rgba(255,255,255,1) 16%,transparent);
      z-index: 2;
      height: 60px;
      width: 145px;
      position: absolute;
      left: 16%;
      top: 30%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
    }

    .opacity-der{
        background: rgb(255,255,255);
        background: linear-gradient(90deg, transparent, rgba(255,255,255,1) 76%);
        z-index: 2;
        height: 60px;
        width: 145px;
        position: absolute;
        left: 84%;
        top: 30%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
    }

    .btnBanner-homeMobile-verRecetas{
      font-size: 13px;
      padding-left: 12px;
      padding-right: 12px;
    }

    .btnBanner-homeMobile-promoFut{
      font-size: 13px;
      padding-left: 12px;
      padding-right: 12px;
    }

    .btnBanner-homeMobile-verProductos{
      margin-left: 15px;
      font-size: 13px;
      padding-left: 12px;
      padding-right: 12px;
    }
  }
.sombra{ 
    box-shadow: 1px 2px 22px 7px rgba(0,0,0,0.3);
  }
  .info-te-sorprendemos{
    z-index: 10;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    top: 46%;
    right:  -17%;
    padding: 10px;
    position: absolute; 
}
.menu-desk {
  background: #ffffff;
  position:fixed;
  top: 0px;
  left: 50%;
  display:-webkit-flex;
  display:flex;
  -webkit-justify-content:space-between;
          justify-content:space-between;
  -webkit-align-items:center;
          align-items:center;
  z-index: 51;
  width: 100%;
  padding-left: 2%;
  padding-right: 1%;
  height: 60px;
  max-width: 1440px;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
}
.item-hover-product{

  font-family:AvenirBlack;
  font-size: 40px;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  color: #000000;
  cursor: pointer;
}
.my-collapse{
 right: 5% !important;
 left: 44% !important; 
}
.item---menu{
  height: 100%;
  padding: 12px;
  font-size: 20px;
  border-bottom: 5px solid transparent;
  color: black;
  cursor: pointer;
}
.logo-header {
    width: 196px;
    height: 42px;
    cursor: pointer;
  }

.menu-mobile{
  display: none
}
@media screen and (max-width: 687px), (max-height: 325px) {
  .menu-desk{
    display: none
  }

  .menu-mobile {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    height: 49px;
    padding: 10px;
    position: fixed;
    top: 0px;
    width: 100%;
    background: white;
    z-index: 13;
  }

  .logo-header {
    width: 122px;
    height: 26px;
    position: absolute;
    left: 20px;
    margin-top: 4px;
  }

}
.spin{
    -webkit-animation: spin 1.5s linear ;
    animation: spin 1.5s linear ;
  }
  @-webkit-keyframes spin {
  	0%{ -webkit-transform: rotate(0deg); tranform: rotate(0deg);}
	100%{ -webkit-transform: rotate(360deg); tranform: rotate(360deg);}
}

@keyframes spin {
	0%{ -webkit-transform: rotate(0deg); transform: rotate(0deg);}
  100%{ -webkit-transform: rotate(360deg); transform: rotate(360deg);}
}

.MuiTypography-body1{
    width: -webkit-fill-available !important;
}

/* Position and sizing of burger button */
.bm-burger-button {
  display: none;
    position: fixed;
    width: 36px;
    height: 30px;
    right: 26px;
    top: 20px;
    z-index: 1032 !important;
  }
  
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #373a47;
  }
  
  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: #a90000;
  }
  
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }
  
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    top: 0px !important;
    position: fixed;
    height: 100%;
    width: 30% !important;
  }
  
  /* General sidebar styles */
  .bm-menu {
    background: #ffffff ;
    padding: 2.5em 1.5em 0;
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
  }
  
  /* Individual item */
  .bm-item {
    display: inline-block;
    outline: none
  }
  
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
    z-index:1032 !important;
    top: 0px !important;
  }


  .bm-burger-button {
    display: block;
  }
  .bm-cross-button{
    display:none !important; 
}
    .menu-item--smal{
        font-size: 1.5vw !important;
        color:black;
        font-family:AvenirBlack !important;
        text-decoration: none;
        margin-left: 12%;
        height: auto;
        width: 80%;
        text-align: start;
        margin-top: 13%
       
    }
    
    .menu-item--smal:hover{
        font-size: 2vw;
        color: #ffff;
        text-decoration: none;
        border-style: none;
        margin-left: 12%;
    }
    .lineas{
        width: 100%;
        height: 1px;
        padding: 0px;
        margin: 0px;
        pointer-events: none;
        cursor: default;
        text-decoration: none;
    }
    .bm-item-list{
        padding: 0px !important;
    }
    .movil-menu-logo{
        display: block;
        height: 6%;
        width: 100%;
        pointer-events: none;
        cursor: default;
        text-decoration: none;
        outline: none !important;
        margin-bottom: 12%;
    }
    a:hover{
        -webkit-text-decoration: transparent;
                text-decoration: transparent
    }
    .social-icon-movil{
        height: 25%;
        width: 17%;
        margin-left: 0%;
        cursor: pointer;
    }
   
   .productos-mobile{
      display: none !important;
    }
    @media screen and (max-width: 687px), (max-height: 325px) {
   
     .productos-mobile{
        display: -webkit-flex !important;
        display: flex !important;
      }
      .menu-item--smal{
        font-size: 16px !important;
    }
    .bm-menu-wrap {
      width: 100% !important;
    }
  
  }

.tam-closeIcon{
    width: 17px;
    height: 16px;
    position: absolute;
    top: 25px;
    right: 30px;
    z-index: 2;
}

.metismenu {
    background: white;
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    font-weight: 900;
    overflow: hidden;
}

.metismenu>.metismenu-container>.metismenu-item>.metismenu-link {
    line-height: 2.5em
}

.metismenu > .metismenu-container > .metismenu-item > .metismenu-container.visible > .metismenu-item .metismenu-item > .metismenu-link{
    color: #bababa;
    font-size: 14px;
}

.metismenu > .metismenu-container > .metismenu-item > .metismenu-container.visible > .metismenu-item .metismenu-item > .metismenu-link.active{
    background: #eeeeee;
}


.metismenu > .metismenu-container > .metismenu-item > .metismenu-container.visible > .metismenu-item{
    border-bottom: 2px solid rgba(0, 0, 0, .04);
    font-size: 16px;

}

.metismenu>.metismenu-container>.metismenu-item>.metismenu-link .metismenu-state-icon {
    line-height: 2.5em
}

.metismenu::after {
    content: " ";
    pointer-events: none;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0
}

.metismenu-container, .metismenu-item {
    margin: 0;
    padding: 0
}



.metismenu-container {
    list-style: none
}

.metismenu-container .metismenu-container {

    
    transition: padding .3s;
    -webkit-transition: padding .3s;
    background: rgba(255, 255, 255, .05);
}

.metismenu-container .metismenu-container .metismenu-item>.metismenu-link {
    height: 0;
    overflow: hidden
}

.metismenu-container .metismenu-container .metismenu-link {
    padding-left: 1em
}

.metismenu-container .metismenu-container .metismenu-container .metismenu-link {
    padding-left: 1.6em
}

.metismenu-container.visible {
    padding: .5em 0
}

.metismenu-container.visible>.metismenu-item>.metismenu-link {
    height: 2.5em
}

.metismenu-link {
    color: black;
    transition: height .3s, color .3s, background-color .3s;
    -webkit-transition: height .3s, color .3s, background-color .3s;
    display: block;
    line-height: 2.5em;
    text-decoration: none
}

.metismenu-link:hover {
    background: #ededed;
    color: #000000
}

.metismenu-link.active {
    background: #ededed;
    color: #000000
}

.metismenu-link.has-active-child {
    color: #000000
}

I.metismenu-icon {
    text-align: center;
    width: 3em
}

I.metismenu-state-icon {
    margin-right: 30px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    -webkit-transition: transform .3s;
    float: right;
    line-height: 2.5em;
    text-align: center;
    width: 1em
}

I.metismenu-state-icon.rotate-minus-90 {
    transform: rotate(-180deg);
    -webkit-transform: rotate(-180deg)
}

.bm-item {
     display: block;
}

.bm-menu {
    background: #ffffff;
    padding: 4.5em 0em 2.5em;
}

.fa {
    display: inline-block;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.fa-caret-left:before {
    max-width: 16px;
    content: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE5LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgdmlld0JveD0iMCAwIDQ5MS45OTYgNDkxLjk5NiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDkxLjk5NiA0OTEuOTk2OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DQo8Zz4NCgk8Zz4NCgkJPHBhdGggZD0iTTQ4NC4xMzIsMTI0Ljk4NmwtMTYuMTE2LTE2LjIyOGMtNS4wNzItNS4wNjgtMTEuODItNy44Ni0xOS4wMzItNy44NmMtNy4yMDgsMC0xMy45NjQsMi43OTItMTkuMDM2LDcuODZsLTE4My44NCwxODMuODQ4DQoJCQlMNjIuMDU2LDEwOC41NTRjLTUuMDY0LTUuMDY4LTExLjgyLTcuODU2LTE5LjAyOC03Ljg1NnMtMTMuOTY4LDIuNzg4LTE5LjAzNiw3Ljg1NmwtMTYuMTIsMTYuMTI4DQoJCQljLTEwLjQ5NiwxMC40ODgtMTAuNDk2LDI3LjU3MiwwLDM4LjA2bDIxOS4xMzYsMjE5LjkyNGM1LjA2NCw1LjA2NCwxMS44MTIsOC42MzIsMTkuMDg0LDguNjMyaDAuMDg0DQoJCQljNy4yMTIsMCwxMy45Ni0zLjU3MiwxOS4wMjQtOC42MzJsMjE4LjkzMi0yMTkuMzI4YzUuMDcyLTUuMDY0LDcuODU2LTEyLjAxNiw3Ljg2NC0xOS4yMjQNCgkJCUM0OTEuOTk2LDEzNi45MDIsNDg5LjIwNCwxMzAuMDQ2LDQ4NC4xMzIsMTI0Ljk4NnoiLz4NCgk8L2c+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8L3N2Zz4NCg==");
}




.child-container{
    height: calc( 100vh - 60px ) ;
    top: 60px;
    width: 100%;
    overflow: none;
    background: white;
    position: fixed;
    z-index: 29;
    /*visibility: hidden;*/

}

.close-button-modal{
    position: absolute;
    height: 28px;
    top: 15px; 
    right: 29px;
}

.header-menu {
    height: 60px;
    top: 0px;
    width: 100%;
    background-color: white;
    z-index: 31;
    position: fixed;
}
.header-menu-text{
    position: absolute;
    font-family: BarlowRegular;
    color: black;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 100%;
    letter-spacing: normal;
    top: 18px;
    margin-left: 4.3%;
    height: 100%;
    text-align: center;
}


@media only screen and (max-width:700px){
    .child-container{
        top: 49px;
        height: calc( 100vh - 49px ) ;
    }
    .header-menu {
        height: 49px;
    }
    .header-menu-text{
       top: 14px 
    }
    .close-button-modal{
    top: 10px; 
    right: 5px;
    }

}
.container-collapse{
    position: relative;
    border-top: 3px solid #fed86b;
    padding: 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-flex-direction: column;
            flex-direction: column;
    font-family: AvenirBook !important;
    font-size: 18px !important;
}
.mermelada{
  position: relative;
    background: #ffffff;
  width: 100%;
  height: 200px;
  position: relative;
  display: -webkit-flex;
  display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
  overflow: hidden;

}


.footerlinks{
  text-align: left;
 
}

.contenedor{
  width: 100%;
  padding-right: 2%;
  margin-right: 12%;
  margin-left: auto;
}

.footer {
  -webkit-flex-direction: column;
          flex-direction: column;
    position: relative;;
    left: 0;
    bottom: 0;
    color:#ffffff;
    width: 100%;
    background: #d41246;
    text-align: center;
    display: -webkit-flex;
    display: flex;
    padding: 10px;
    height: 200px;
  }
  .footer-mobile{
    display: none; 
    width: 100% ;
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }
  .footer-desk{
    display: -webkit-flex;
    display: flex; 
    width: 100% ;
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }
  .icon-footer{
    height: auto;
    width: 20%;
    cursor: pointer;
    padding-right: 10%;
    padding-top: 10%
  }
  .social-footer{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly; 
    /* align-items:center;  */
    width: 50%;
    left: 35%;    

  }

  .mermeladaM{
    display: none;
  }
 

  @media screen and (max-width: 687px), (max-height: 325px) {
   
    .footer{
      -webkit-flex-direction: column;
              flex-direction: column;
      padding: 2% !important;
      width: auto;
      height: auto;
    
      z-index: 20;
    }
    .social-footer{
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-evenly;
              justify-content: space-evenly; 
      -webkit-align-items:center; 
              align-items:center; 
      width: 100%;
    }
    .footer-mobile{
      display: -webkit-flex;
      display: flex;
      height:auto;
    }
    .footer-desk{
      display: none
    }
    .mermeladaM{
      height: 150px;
    }
    .icon-footer{
      height: 50%;
      width: 30%;
      -webkit-justify-content: space-evenly;
              justify-content: space-evenly;
      padding-right: 0%; 
      padding-bottom: 0%;
      padding-top: 0%
    }

    
   
  }
.MAYONESA-CON-CHIPOTL {
  
  cursor:pointer;
  font-family: CocogooseProRegular;
  font-size: 25px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.24;
  letter-spacing: 4px;
  color: #732626;
}

td {
  font-family: AvenirBook;
}
.container-regresar{
  margin-left: 5%;
}
.REGRESAR-A-MAYONESAS {
  cursor: pointer;
 
margin: 0px;
  font-family: CocogooseProRegular;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 3.29;
  letter-spacing: normal;
  color: #000000;
}
.container-regresar-mov{
  margin-left: 5%;
  margin-top: 10px;
}
.collapsible {
  width: 100%;
  cursor: pointer;
  font-family: CocogooseProRegular;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 3.29;
  letter-spacing: 2.19px;
  color: #000000;
  
}
.flecha-anterior{
  max-width: 100%;
  height: auto;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  left: 10%;
  position: absolute;
  cursor:pointer;
}
.product-hover{
  top: auto !important;
  bottom: 18%
}
.producto-div-container{
  -webkit-transform: translate(50%, 0);
          transform: translate(50%, 0);
  max-width: 28vw;
  float: right;
  height: 500px;
}
.producto{
  object-fit: contain;
  width: 100%;
  height: 100%;
  /*max-width: 20vw;*/

}
.producto-fondo{
  position: absolute;
  right: 0;
  max-height: 400px; 
  width: 100%;
  object-fit: cover;
}
.content {
  padding: 0 18px;
  display: none;
  overflow: hidden;
  background-color: #f1f1f1;
}
.PRESENTACIONES {
  width: 140px;
  height: 16px;
  font-family: CocogooseProRegular;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 1.8px;
  color: #000000;
}
.detalle-desk{
  display: block
}
.detalle-desk p{
  font-family: AvenirBook;
}
.detalle-mobile p{
  font-family: AvenirBook;
}
.detalle-mobile{
  display: none
}
@media screen and (max-width: 687px), (max-height: 325px) {
  .detalle-desk{
    display: none
  }
  .detalle-mobile{
    display: block
  }
}
@media screen and (min-width: 1600px) {
  .producto-div-container{
    max-width: 61%;
  }
}




.btn-detox{
  border-radius: 10px;
  background: #2D8ED0;
  color: #ffffff;
  padding-left: 30px;
  padding-right: 30px;
  border: 0px;
}
.cont-btn-detox{
      /* font-family: CocogooseProRegular; */
      width: 60%;
      z-index: 10;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      font-family: CocogooseLight;
      top: 80%;
      display: -webkit-flex;
      display: flex;
    -webkit-justify-content: center;
            justify-content: center;
      left: 50%;
      position: absolute;
      text-align: center;
}
.Especiales {
    width: 763px;
    height: 45px;
    font-family: CocogooseProRegular;
    font-size: 32px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.41;
    letter-spacing: -0.76px;
    text-align: center;
    color: #8d9495;
   
  }

  .btn-detalle-producto{
    background: #ffffff;
    border: 3px solid #ffbd00;
    color: black;
   
    border-radius: 15px;
    font-family:AvenirBlack;
    padding-left: 15px;
    padding-right: 15px;
    transition: 0.5s;
    margin: 5px;

}
.btn-detalle-producto:hover{
  border: 3px solid #ffbd00;
  color: black;
  background: #ffbd00;
}
.filtro-productos-detalle{
  font-family:AvenirBook;
  background: #ffffff;
  background: #ffffff;
  box-shadow: 2px 2px 10px #666;  
  position: relative;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  margin-top:-6%;
  left: 50%;
  z-index: 2;
  width: 80%;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px
}

.detalle-productos-desk  {
  display: block
  }
.detalle-productos-mobile   {
  display: none
  }
  
  


@media screen and (max-width: 687px), (max-height: 325px) {
  .btn-detox{
 
    font-size: 13px;
    padding-left: 2px;
    padding-right: 2px;
    border: 0px;
  }
  .cont-btn-detox{
        /* font-family: CocogooseProRegular; */
        width:55%;
        z-index: 10;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        top: 91%;
        display: -webkit-flex;
        display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-justify-content: flex-start;
              justify-content: flex-start;
        left: 34%;
        position: absolute;
        text-align: center;
  }

  .detalle-productos-desk{
     display: none
    }

  .detalle-productos-mobile{
      display: block
    } 
    
    

  .filtro-productos-detalle{
    font-family:AvenirBook;
    background: #ffffff;
    background: #ffffff;
    box-shadow: 2px 2px 10px #666;  
    position: relative;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    margin-top:-6%;
    left: 50%;
    z-index: 2;
    width: 93%;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px
  }



}
.btn-recetas{
    border: 1px solid #ffffff;
    color: #ffffff;
    background: transparent;
    border-radius: 15px;
    font-family:AvenirBlack;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 17px;
    transition: 0.3s;
}

.zoom:hover { /* IE 9 */
  -webkit-transform: scale(1.5); /* Safari 3-8 */
  transform: scale(1.5); 
}
  .banner-recetas-button-desk{
   position: absolute;
   margin-top: 7%;
   top: 50% !important;
   text-align: center;
   width: 100%;  
  }


.btn-recetas:hover{
    background: #ffbd00;
    color: black;
    border: 1px solid #ffbd00;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.28);
    /*-ms-transform: scale(1.15); /* IE 9 
    -webkit-transform: scale(1.15); /* 
    transform: scale(1.15);*/ 
}
.btn-receta-static{
    margin-right: 20px;
    border-radius: 15px;
    font-family:AvenirBlack;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 17px;
    background: #ffbd00;
    color: black;
    border: 1px solid #ffbd00;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.28);
}
.swiper-container{
    background: #ffffff !important;
}
.MS-POPULARES {
 
    font-family: AvenirBook;
    font-size: 16px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.88;
    letter-spacing: 2.5px;
    color: #d41245;
  }
  .LAS-RECETAS-MS-POPU {
   
    font-family:CocogooseProRegular;
    font-size: 42px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: normal;
    color: #000000;
  }
.filtro-recetas{
    background: #ffffff;
    background: #ffffff;
    box-shadow: 2px 2px 10px #666;  
    position: relative;
    -webkit-transform: translate(-50%, 14%);
            transform: translate(-50%, 14%);
    margin-top: -6%;
    left: 50%;
    width: 88%;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    z-index: 1;


}
.UN-SINFN-DE-RECETAS {
  position: absolute;
  -webkit-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  left: 50%;
  width: 90%;
  top:40%;
 
    text-shadow: 4px 8px 16px rgba(0, 0, 0, 0.59);
    font-family:CocogooseProRegular;
    font-size: 71px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: 2.14px;
    text-align: center;
    color: #ffffff;
  }

.select-recetas{
    border: 4px solid #ffd766;
    width: 20%;
    height: auto;
    text-align: center;
    font-family: AvenirBook;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 3.29;
    letter-spacing: 2.19px;
    color: #000000;
}
.recetas-mobile{
  display: none
}
.recetas-desk{
  display: block;

}

@media screen and (max-width: 687px), (max-height: 325px) {
  .recetas-mobile{
    display: block
  }

  .recetas-desk{
    display: none;
    margin-top: 10px;
  }

  .UN-SINFN-DE-RECETAS {
    position: relative;
    font-size: 40px;
    width: 100%;
    text-align: center;
    top: 38% ;
    left: 0%;
    -webkit-transform: none;
            transform: none;
    letter-spacing: .95px;
    line-height: 41px;
    margin-bottom: 12px;
  }

  .banner-recetas-button-desk{
   display: none;
  }

  /* Inicio de ajustes para Filtrar por mobile */
  .div-filtrar-por{
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    margin-top: -58px;
  }

  .divSon-filtrar-por{
    background: rgb(255, 255, 255);
    width: 96%;
    height: 59px;
    padding-left: 21px;
    padding-right: 21px;
    padding-top: 10px;
    padding-bottom: 7px;
    z-index: 2;
    box-shadow: -3px 16px 26px rgba(0, 0, 0, 0.18);
  }

  /* Ajustes de diseño para FILTRAR POR en ExpansionPanel */
  .MuiExpansionPanelSummary-root {
    display: -webkit-flex;
    display: flex;
    padding: 5px 24px 5px 24px !important;
    min-height: 38px !important;
    height: 38px;
    transition: min-height 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  /* Quitar sombra de filtrar por */
  .MuiPaper-elevation1 {
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0), 0px 0px 0px 0px rgba(0,0,0,0), 0px 1px 3px 0px rgba(0,0,0,0) !important;
  }

  /* Personalización de texto para FILTRAR POR en ExpansionPanel */
  .MuiTypography-body1 {
    font-size: .8rem !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500 !important;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    text-align: left;
    width: 100% !important;
  }

  /* margen de sub-sub-menu*/
  .MuiExpansionPanelDetails-root {
    display: -webkit-flex;
    display: flex;
    padding: 8px 24px 12px !important;
  }

  .MuiIconButton-edgeEnd {
    margin-right: -6px !important;
  }

  .css-1okebmr-indicatorSeparator {
      width: 0 !important;
  }

  .css-tlfecz-indicatorContainer {
    padding: 8px !important;
  }
  
  .css-1wa3eu0-placeholder {
    color: black !important;
  }

  .css-tlfecz-indicatorContainer {
    color: hsl(0,0%,50%) !important;}

  /* Fin de ajustes de diseño de Filtrar por mobile*/

  .tam-img{
    width: 100%;
    height: 392px;
    object-fit: cover;
    object-position: 50% 75%;
  }

  .tam-iconoX{
    width: 16px;
    height: 16px;
    position: absolute;
    top: 16px;
    right: 30px;
    z-index: 2;
  }

  .SABOR-TRADICIONAL{
    font-family: SignPainter;
    font-size: 20px;
    width: 100%;
    height: 44px;
    font-weight: lighter;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.15;
    letter-spacing: 1.2px;
    text-align: center;
    color: #ffffff;
    margin-bottom: 21px;
  }

  .BTN-TODAS{
    background: #ffbd00;
    border: 1px solid #ffbd00;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.28);
    color: #000;
    border-radius: 16px;
    font-family: AvenirBlack;
    font-size: 14px;
    width: 67.5%;
    height: 32px;
    cursor: pointer;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .BTN-TODAS2{
    background: #ffbd00;
    color: #000;
    border: 1px solid #ffbd00;
    border-radius: 16px;
    font-family: AvenirBlack;
    font-size: 14px;
    width: auto;
    height: 32px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 25px;
    padding-right: 25px;
    margin-right: 15px;
  }


  .RECETAS-BANNER{
    width: 100%;
    -webkit-transform: translate(-50% ,-50%);
            transform: translate(-50% ,-50%);
    position: absolute;
    top: 45%;
    left: 50%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
  }

  .RECETAS-BANNER2 {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    position: absolute;
    left: 0;
    top: 8vh;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
    min-width: 284px;
    grid-gap: 2% ;
  }
  .RECETAS-BANNER2 > button {
    margin-bottom: 7px;
    text-align: center;
   }

  .btn-recetas {
    border: 1px solid #ffffff;
    color: #ffffff;
    background: transparent;
    border-radius: 16px;
    height: 32px;
    font-family: AvenirBlack;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 17px;

  }

  .btn-recetas:hover {
    background: #ffbd00;
    border: 1px solid #ffbd00;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.28);
    color: white;
    /*transform: scale(1.15);*/
  }
  
  .MS-POPULARES {
   
    font-family: AvenirBlack;
    font-size: 10px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    color: #d41245;
  }

  .LAS-RECETAS-MS-POPU {
    
    font-family:CocogooseProRegular;
    font-size: 22px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: normal;
    color: #000000;
  }

  .btn-cargar-mas{
    /* padding-left: 40px;
    padding-right: 40px;
    font-family: AvenirBlack;
    margin-top: 10px;
    border: 3px solid #d41246;
    border-radius: 15px;
    transition: 1.0s;
    background: #d41246;
    color: #FFFFFF;
    cursor: pointer; */
    padding-left: 40px;
    padding-right: 40px;
    font-family: AvenirBlack;
    margin-top: 10px;
    border-radius: 15px;
    transition: 1.0s;
    background: transparent;
    border: 3px solid #bfbfbf;
    color: #bfbfbf;
    cursor: pointer;
  }
  
}

@media screen and (max-width: 1024px), (max-height: 325px) {
 
}

.container-found-page{
  width:100%;
  height:100vh;

  
  font-family: JustTellMeWhat;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  color: #001a44;
}
.container-found-page-mobile{
  display: none;
}


.No-encontramos-tu-s {
 
  font-family:CocogooseProRegular;
  font-size: 1.2vw;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.24;
  letter-spacing: 2px;
  color: #000000;
}
.Error-404 {
  
  font-family: CocogooseProRegular;
  font-size: 2.3vw;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.24;
  letter-spacing: 2px;
  color: #a4a4a4;
}
.div-image{
  width:50%;
  display:-webkit-flex;
  display:flex;
  -webkit-justify-content:flex-end;
          justify-content:flex-end;
  -webkit-align-items:center;
          align-items:center;
}
.found-page{
  width: 100%; 
  font-size: 2vw; 
  display: -webkit-flex; 
  display: flex;
}
.texto-container{
  width:50%;
  padding:20px
}
.texto-info{
  font-size: 1.2vw
}
@media screen and (max-width: 687px), (max-height: 325px) {
  .container-found-page{
    display: none;
  }
  .texto-info{
    font-size: 15px
  }
  .texto-container{
    width:100%;
  }
  .div-image{
    width:100%;
    display:-webkit-flex;
    display:flex;
    -webkit-justify-content:center;
            justify-content:center;
    -webkit-align-items:center;
            align-items:center;
  }
  .found-page{
   
    display: -webkit-flex;
   
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .No-encontramos-tu-s {
    font-size: 25px;
  }
  .Error-404 {

    font-size: 25px;
   
  }
}
.info-productos{
    font-family: CocogooseProRegular;
    width: 80%;
    z-index: 10;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    position: absolute;
    text-align: center;
}
.MS-ENERGA {
    
    text-shadow: 4px 8px 16px rgba(0, 0, 0, 0.59);
    font-family: CocogooseProRegular;
    font-size: 90px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.94;
    letter-spacing: 2.14px;
    text-align: center;
    color: #ffffff;
  }

  .El-sabor-tradiciona {
    
  
    font-family: SignPainter;
    font-size: 42px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.52;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }
  .filtro-productos{
    font-family:AvenirBook;
    background: #ffffff;
    background: #ffffff;
    box-shadow: 2px 2px 10px #666;  
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    bottom:-10%;
    left: 50%;
    z-index: 1;
    width: 80%;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px
}
.QUIERO-VER {
    font-family: CocogooseProRegular;
    font-size: 16px;
    margin: 0px;
    margin-right: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.88;
    letter-spacing: 2.5px;
    text-align: center;
    color: #000000;
  }

  .linea-guia{
      position: fixed;
      height: 100%;
      width: 2px;
      background:red;
      margin-left: 100px;
      z-index: 89;
  }
  .NUESTROS-PRODUCTOS {
    margin-top: 10%;
    margin-left: 100px;
    font-family: CocogooseProRegular;
    font-size: 48px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: -1px;
    color: #000000;
  }
  .undefined {
    margin-left: 100px;
    font-family: AvenirBook;
    font-size: 26px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.55;
    letter-spacing: normal;
    color: #000000;
  }

  .info-categorias-productos {
    z-index: 10;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    top: 30%;
    left:30%;
    position: absolute;
}
.product-hover{
  transition:  .2s 
  

}
.product-hover:hover{
  -webkit-transform: scale(1.1) ;
          transform: scale(1.1) ;
transition: 0.5s
  

}


.option-class{
  background: #ffd766
}
.option-class:hover{
  border: 4px solid #ffd766 !important;
}
.productos-mobile{
  display: none;

}
.productos-desk{
  display: block;
}

@media screen and (max-width: 687px), (max-height: 325px) {
  .productos-mobile{
    display: block;
  }
  .productos-desk{
    display: none;
  }

  .filtro-productos{
    font-family:AvenirBook;
    background: #ffffff;    
    box-shadow: 2px 2px 10px #666;  
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    bottom:-52%;
    left: 50%; 
    z-index: 1;
    width: 95%;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px
    
}

.filtro-productos-center{
  text-align: center;
  text-align: -webkit-center;
  background-color: #ffffff;

}

.NUESTROS-PRODUCTOS{

  font-family: CocogooseProRegular;
    font-size: 4.4vw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: -1px;
    color: #000000;
    text-align: center;
    margin-left: 20px;
    margin-top: 10%;
}

.mayonesas{
  font-size: 16px;
  color: rgb(212, 18, 70);
  font-family:CocogooseProRegular;
  margin-left: 2%;
  margin-top: 7%;
}

.mayosub{

    font-family: CocogooseProRegular;
    font-size: 4.4vw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: 3px;
    color: #000000;
    text-align: left;
    margin-left: 10px;
}

.undefined {
  margin-left: 23px;
  font-family: AvenirBook;
  font-size: 3vw;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.55;
  letter-spacing: normal;
  color: #000000;
}

.info-categorias-productos {
  z-index: 10;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  top: 30%;
  left:50%;
  position: absolute;
}

}

.CONTACTO {
    width: 347px;
    height: 31px;
    font-family: CocogooseProRegular;
    font-size: 42px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.74;
    letter-spacing: 6.72px;
    color: #000000;
    margin-left: 5%;
}
.imput-contacto{
    border-radius: .25rem;
    border: 1px solid  gray;
    padding: 5px;
}
.imput-contacto:focus{
    border-color: #fed767;
    border: 3px solid #fed767;
    outline: none !important;
    
}
.Se-envio-tu-mensaje {
    margin-top:50px;
    font-family: CocogooseProRegular;
    font-size: 20px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.55;
    letter-spacing: 1px;
    text-align: center;
    color: #000000;
  }
  .form-contacto{
    width: 40% ;
    margin-top: 50px ;
    display: -webkit-flex;
    display: flex; 
    -webkit-justify-content: center ; 
            justify-content: center ;
    -webkit-align-items: center;
            align-items: center; 
    -webkit-flex-direction: column; 
            flex-direction: column;
}
.contacto-container{
        width: 100%; 
        margin-top: 50px ;
        padding: 35px
}

.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-radius: 5px;
  border: solid 3px #d41245;
}


/*.MuiInputLabel-outlined {
  font-family: AvenirBook !important;
}*/

  @media screen and (max-width: 687px), (max-height: 325px) {
      .form-contacto{
            width: 100% ;
           
      }
      .contacto-container{
        width: 100%; 
        margin-top: 10px ;
        padding: 20px
}

  }
.container-promociones{
    margin-top: 6px;
    padding: 30px;
    padding-top: 0px;
}
.btn-promo-juego{
    padding-left: 150px;
    padding-right: 150px;
    font-family:AvenirBlack;
    margin-top: 10px;
   border: 3px solid #d41246;
    border-radius: 15px;
    transition: 1.0s;
    font-size: 2vw;

    background: #d41246;
    color: #FFFFFF;
    margin-top: 19px;
    margin: 2.9% 10px 10px;
}
.btn-promo-juego:hover {
    background: transparent;
    border: 3px solid  #d41246 !important;
    color: #d41246;
    transition: 1.0s;
    cursor: pointer;
}
.btn-promo-tokens{
    padding-left: 150px;
    padding-right: 150px;
    font-family:AvenirBlack;
    margin-top: 10px;
   border: 3px solid #35BEAD;
    border-radius: 15px;
    transition: 1.0s;
    font-size: 2vw;

    background: #35BEAD;
    color: #FFFFFF;
    margin-top: 19px;
    margin: 2.9% 10px 10px;
    
}
.btn-promo-tokens:hover {
    background: transparent;
    border: 3px solid #35BEAD !important;
    color: #35BEAD;
    transition: 1.0s;
    cursor: pointer;
}
.container-promo-btn{
    margin-top: 0px;
    margin-bottom: 40px;
}
.btn-promo{
    padding-left: 40px;
    padding-right: 40px;
    font-family:AvenirBlack;
    margin-top: 10px;
   border: 3px solid #d41246;
    border-radius: 15px;
    transition: 1.0s;
    font-size: 2vw;

    background: #d41246;
    color: #FFFFFF !important;    
}

.btn-promo:hover {
    background: transparent;
    border: 3px solid #d41246 !important;
    color: #d41246 !important;
    transition: 1.0s;
    cursor: pointer;
}

    


.swiper-promociones .swiper-button-next{
  
    background-image: url(/static/media/der.42b98338.svg);
}
.swiper-promociones .swiper-button-prev{
    background-image: url(/static/media/isq.a86bf6c8.svg);
}
.swiper-promociones .swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
    /* bottom: 129px !important; */
    

}
.promoBanner{
    height: 88vh;
}
.collapsible-promociones {
    font-family: CocogooseProRegular;
    font-size: 42px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    
    color: #000;
}
.title-promo > label{
        font-size: 42px;
    }
    .video-juego{
        height:600px;
        width:100%;
    }
@media screen and (max-width: 687px), (max-height: 325px) {
    .promoBanner{
        height: auto;
    }
    .video-juego{
        height:175px;
        width:100%;
    }
    .btn-promo-juego{
        padding-left: 40px;
        padding-right: 40px;
        font-family:AvenirBlack;
        margin-top: 10px;
       border: 3px solid #d41246;
        border-radius: 15px;
        transition: 1.0s;
        font-size: 1rem;
    
        background: #d41246;
        color: #FFFFFF;
        margin-top: 19px;
        margin: 2.9% 10px 10px;
    }
    
    .btn-promo-tokens{
        padding-left: 40px;
        padding-right: 40px;
        font-family:AvenirBlack;
        margin-top: 10px;
       border: 3px solid #35BEAD;
        border-radius: 15px;
        transition: 1.0s;
        font-size: 1rem;
    
        background: #35BEAD;
        color: #FFFFFF;
        /* margin-top: 13px; */
        margin: 13px 10px 10px;
        
    }

    .btn-promo{
        padding-left: 40px;
        padding-right: 40px;
        font-family:AvenirBlack;
        margin-top: 10px;
       border: 3px solid #d41246;
        border-radius: 15px;
        transition: 1.0s;
        font-size: 1rem;
    
        background: #d41246;
        color: #FFFFFF;
        /* margin-top: 13px; */
        margin: 13px 10px 10px;
        
    }
    
    .btn-promo:hover {
        background: transparent;
        border: 3px solid #d41246 !important;
        color: #d41246;
        transition: 1.0s;
        cursor: pointer;
    }
    .swiper-promociones .swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
        bottom: 100px !important;
    
    }
    .container-promociones{
        margin-top: 0px;
        padding: 10px
    }
.title-promo > label{
        font-size: 22px !important;
    }
    .collapsible-promociones {
        font-size: 20px;
    }
}
.ANTOJITOS {
  cursor: pointer;
    font-family: AvenirBook;
    font-size: 14px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 3.29;
    letter-spacing: 2.19px;
    color: #d41245;
  }
  .prod-util-prin{
    background: white;
    position: relative;
    width: 100%;
    height: 240px;
  }
  .div-color-prod{
    position: absolute;
    height: 143px;
    width: 100%;
    bottom: 0;
    background: #b8e986;

  }
  .productos-swiper > .swiper-container{
    background: none !important;

  }
  .productos-swiper{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 50%;
  }
  .list-preparacion li:nth-child(even){
    background: gainsboro;
  }
  .ROLLITO-DE-VEGETALES {
   
    font-family: CocogooseProRegular;
    font-size: 26px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: 1px;
    color: #000000;
  }
  .Sirve-este-excelente {
 
    font-family: AvenirBook;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: normal;
    color: #000000;
  }
  .INGREDIENTES {
    
    font-family: CocogooseProRegular;
    font-size: 16px;
   
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    color: #417505;
  }
  .PARA-ESTA-RECETA-NEC {
    position: absolute;
    width: 272px;
    bottom: 25px;
    left: 23px;
    font-family: CocogooseProRegular;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: normal;
    color: #000000;
  }
.detalle-receta-desk{
    display: block
}

.detalle-receta-mobile{
    display: none      
}
.donde-comprar {
  font-family: CocogooseProRegular;
  font-size: 26px;
  padding: 50px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  line-height: 1.38;
  letter-spacing: 1px;
  color: #000000;
}
.container-tiendas{
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap:wrap;
          flex-wrap:wrap
}
.container-logo{
  -webkit-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%;
}
@media screen and (max-width: 687px), (max-height: 325px) {
  .container-logo{
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .container-tiendas{
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap:wrap;
            flex-wrap:wrap;
    -webkit-flex-direction: column;
            flex-direction: column;
    
  }
    .detalle-receta-desk{
        display: none
    }
    .donde-comprar {
      font-size: 15px;
      padding: 15px
    }
    
    .detalle-receta-mobile{
        display: block      
    }



    .ANTOJITOS {
 
    text-align: justify
      }
      .ROLLITO-DE-VEGETALES {
       
     font-size: 20px
      }
      .Sirve-este-excelente {
     
        text-align: justify
      }
      .INGREDIENTES {
      text-align: justify
      }
     
}
.titulo-nosotros{
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  width: 97%;
  line-height: 1.44;
  letter-spacing: 4px;
  text-align: center;
  color: #000000;
  font-family: CocogooseProRegular;
}
.titulo-nosotrosH{
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  width: 97%;
  line-height: 1.44;
  letter-spacing: 4px;
  text-align: left;
  text-align: initial;
  color: #000000;
  font-family: CocogooseProRegular;
}
.texto-nosotros {
  font-family: AvenirBook;
  font-size: 21px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.55;
  letter-spacing: normal;
  color: #000;
}

.nosotros-container{
  width: 100%;
  margin-top: 100px;
}

.nosotros-container-sombra{
  width: 100%;
  box-shadow: 0 7px 47px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  padding: 0px 14% 0 11%;
}

.linea-nosotros{
  position: absolute;
  z-index: 1;
  top: -2%;
  left: -2.5%;
  height: 623px;
  width: 623px;
}

.titulo-principal{
  font-family: CocogooseProRegular;
  font-size: 42px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: -1px;
  color: #000000;
  margin: 150px 0 17px;
}

.año-nosotros{
  font-family: CocogooseProRegular;
  font-size: 51px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.73;
  letter-spacing: -1px;
  text-align: center;
  color: #1b2437;
  margin: 15px 0 20px;
}
.descripcion-nosotros {
  font-family: AvenirBook;
  font-size: 19px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: center;
  color: #1b2437;
}

.animation-section {
  width: 100%;
  display: -webkit-flex;
  display: flex; 
  -webkit-justify-content: center; 
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.animation-title{
  font-family: CocogooseLight;
  font-size: 21px;
  line-height: 1.44;
  letter-spacing: 4px;
  color: #000000;
  text-align: center;
}

.contrainer-animation{
  position: relative;
  width: 650px;
  background-image: url(/static/media/linea.37fc3fe7.png);
  background-repeat: no-repeat;
  background-size: 90% 90%;
  height: 650px;
  margin-left:6%;
  margin-bottom: 100px;
}

.img-product-desk{
    display: block;
  }

  .img-product-mob{
    display: none;
  }

@media screen and (max-width: 687px), (max-height: 325px) {

  .contrainer-animation{
    width: 300px;
    background-image: url(/static/media/linea.37fc3fe7.png);
    background-repeat: no-repeat;
    background-size: 90% 90%;
    height: 300px;
  }
  .titulo-principal{    
    font-size: 27px;
    margin: 27px 0 17px;
   
  }
  .titulo-nosotros {
    font-family: CocogooseProRegular;
    font-size: 1.2em !important;
    letter-spacing: 2px;
  }
  .titulo-nosotrosH {
    font-family: CocogooseProRegular;
    font-size: 1.2em !important;
    letter-spacing: 2px;
  }
  .texto-nosotros {
    font-size: 15px;
  }
  .nosotros-container{
    width:100%;
    padding:19px;
    margin-top:10px
  }
  .nosotros-container-sombra{
    width: 100%;
    padding: 19px;
  }
  

  .linea-nosotros {
    position: absolute;
    z-index: 1;
    top: 0%;
    left: -4%;
    height: 274px;
    width: 98%;
  }

  .animation-title{
    font-family: CocogooseProRegular;
    font-size: 13px;
    text-align: center;
    letter-spacing: 3px;
  }
  .año-nosotros {
    font-size: 30px;
    margin: 5px 0 10px;
  }
  .descripcion-nosotros {
    font-size: 10px;
  }
  .img-product-desk{
    display: none;
  }

  .img-product-mob{
    display: block;
  }
}
.productos-desk-tips{
    /* padding-left: 5%;
    padding-right: 5%; */
    display:-webkit-flex;
    display:flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column

    
}
.cont-recetas-img{
    width:100%;
    display:-webkit-flex;
    display:flex;
    -webkit-flex-wrap:wrap;
            flex-wrap:wrap;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
}
.recetas-img{
    max-width: 20%;
}
.productos-mobile-tips{
    position: relative;
}
.banner-tips{
    width: 100%;
    height: auto
}
.title-tips{
    font-size: 3.1vw;
    font-weight: normal;
}
.tips-container{
    padding-left: 5%;
    padding-right: 5%; 
    font-size: 1.5vw; 
    margin-top: 4%;
}
.container-swiper-tips{
    width: 100%; 
    display: -webkit-flex; 
    display: flex; 
    -webkit-justify-content: center; 
            justify-content: center; 
    padding-left: 30px;
    padding-right: 30px
}
.swiper-productos-mobile-tips{
    width: 80%
}
.contain-recetas {
    margin-top: 20px;
}
.contain-recetas p{
    margin: 0;
}
@media screen and (max-width: 687px), (max-height: 325px) {
    .banner-tips{
        width: 100%;
        height: 136px
    }
    .container-swiper-tips{
        padding-left: 5px;
        padding-right: 5px   
    }
    .tips-container{
        padding-left: 10px;
        padding-right: 10px; 
        font-size: 16px
    }
    .title-tips{
        font-size: 20px;
        font-weight: normal;
    }
   
    .productos-desk-tips{
      
        display: none;
    }
    .productos-mobile-tips{
        display: block;
        margin-top: 5%;
    }
}

.lds-dual-ring {

  display: contents;
  width: 80px;
  height: 80px;
}
.fb-post{
  min-height: 526px !important;
  border: 0px !important;
  width: 85%;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  top: -3px;
  left: -3px;
  position: absolute;
  width: 94%;
  height: 94%;
  margin: 8px;
  border-radius: 50%;
  border: 5px solid #fff;
  border-color: red transparent red transparent;
  -webkit-animation: lds-dual-ring 4s linear infinite;
  animation: lds-dual-ring 4s linear infinite;

}
@-webkit-keyframes lds-dual-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes lds-dual-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.fb_iframe_widget iframe{
  width: 100% !important;

  height: 100% !important;
}
.fb_iframe_widget_fluid span {
  width: 100% !important;
  height: 507px !important;
}

.hvr-float-shadow{
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  transition-duration: 0.3s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}
.hvr-float-shadow:hover{
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
}

.container-tips {
    position: relative;
    cursor: pointer;
  margin-top: 2%;
  }
  .container-tip{
    width: 47%;
    margin-top: 1%;
    /* padding: 0% 3% 0%; */
  }
  
  .image {
    display: block;
    width: 100%;
    height: auto;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color:#000000ac;
  }
  
  .container-tips:hover .overlay {
    opacity: 0.9;
  }
  
  .text {
    color: white;
    font-size: 1em;
    position: absolute;
    padding: 2%;
    top: 50%;
    width: 100%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .facebook-mobile{
    display: none
  }
  .facebook-desk{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    width: 100%;
  }
  .banner-desk{
    display: -webkit-flex !important;
    display: flex !important;
    max-height: 350px;
  }
  .banner-mobile{
    display: none !important
  }
  .container-menu-blog{
    
    font-family: AvenirBlack ;
    font-size: 1.6em;
     width: 90%;
     margin-top: 5%;
  }
  .image-menu{
    max-width: 100%; 
    height: auto; 
    z-index: 1;
    border-radius: 50%;
  }
  .container-menu-item{
    text-align: center;
    font-size: 0.6em;
    font-family: "CocogooseLight";
    width: 25%;
    display: -webkit-flex;
    display: flex; 
    -webkit-flex-direction: column; 
            flex-direction: column;
     -webkit-align-items: center;
             align-items: center; 
     -webkit-justify-content: center; 
             justify-content: center;
      position: relative;
      color: #707070
  }
  .title-blog{
    font-family: AvenirBlack;
     font-size: 1.6em;
    margin-top:4% 
  }
  .redes-text{
    font-family: CocogooseProRegular;
     font-size: 3em
  }
  .insta-img{
    max-width: 100%;
     height: auto
  }
  .title-redes{
     color: #CD0F41; 
     font-family: CocogooseProRegular; 
     margin-left: 1%;
      margin-bottom: 0px;
      font-size: 2em,
  }
  .insta-container{
    width: 30%;
    margin: 0 1% 1%;

  }
  .container-foto{
  width: 66%; 
  position: relative;
  display:-webkit-flex;
  display:flex;
  -webkit-justify-content:center;
          justify-content:center;
  -webkit-align-items:center;
          align-items:center;
  padding:5%
  }
  .container-facebook{
    display: -webkit-flex;
    display: flex; 
    width: 95%; 
    position: relative; 
    -webkit-flex-wrap: wrap; 
            flex-wrap: wrap; 
    -webkit-justify-content: center; 
            justify-content: center 
  }
  .isq-blog{
    max-width: 100%; 
    height: auto; 
    top: 43%; 
    left: 1%; 
    position: absolute;
    cursor: pointer; 
    z-index: 2 
  }
  .der-blog{
    max-width: 100%; 
    height: auto; 
    top: 43%; 
    right: 1%; 
    position: absolute;
    cursor: pointer; 
    z-index: 2 
  }
  .swiper-blog-mobile{
    display: none;
  }
  .swiper-blog{
    display: block
  }
  .imagen-banner-blog{
    width: 100%;
    height: 370px ;
  }
  .container-notas{
     display: -webkit-flex;
     display: flex;position: relative; -webkit-flex-wrap: wrap; flex-wrap: wrap; -webkit-justify-content: space-evenly; justify-content: space-evenly; width: 90%;margin-top:2%
  }
 
  
  @media screen and (max-width: 687px), (max-height: 325px) {
    .container-notas{
      display: -webkit-flex;
      display: flex;position: relative; -webkit-flex-wrap: wrap; flex-wrap: wrap; -webkit-justify-content: space-evenly; justify-content: space-evenly; width: 90%;margin-top:5%
   }
    .imagen-banner-blog{
      max-width: 100%;
      height: 100px ;
    }
    .text{
      font-size:9px
    }
    .swiper-blog-mobile{
      display: block;
    }
    .swiper-blog{
      display: none
    }
    .isq-blog{
      max-width: 7%; 
      
    }
    .der-blog{
      max-width: 7%; 
  
    }
    .container-facebook{
      margin-top: 4%
    }
    .container-foto{
      width: 90%; 
    }
    .insta-container{
      width: 48%
    }
    .title-redes{
      font-size: 14px,
    }
    .insta-img{
      max-width: 13%;
    }
    .redes-text{
 
       font-size: 24px
    }
    .title-blog{
    
       font-size: 18px;
   
    }
    .container-tip{
      width: 100%;
      margin-top: 1%;
      padding: 0%;
    }
    .container-menu-item{
      font-size: 8px;
    }
    .image-menu{
      padding: 6%
    }
    .container-menu-blog{
       width: 88%
    }
    .lds-dual-ring:after {
      content: " ";
      display: block;
      top: -7px;
      left: -7px;
      position: absolute;
      width: 97%;
      height: 97%;
      margin: 8px;
      border-radius: 50%;
      border: 3px solid #fff;
      border-color: red transparent red transparent;
      -webkit-animation: lds-dual-ring 4s linear infinite;
      animation: lds-dual-ring 4s linear infinite;
    
    }
  
    .que-se-antoja{
      display: none
    }
    .banner-desk{
      display: none !important;
      max-height: 350px;
    }
    .banner-mobile{
      display: -webkit-flex !important;
      display: flex !important;
    }
    .facebook-mobile{
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center;
      width: 100%;
    }
    .facebook-desk{
      display: none
    }
  }
.Detox_TitleSection__1rs47 {
    font-family: CocogooseProRegular;
    text-transform: uppercase;
    letter-spacing: 0px;
    color: #0A0A0A;
    text-align: center;
    font-size: 50px;
}

.Detox_FontBlack__3EwXi {
    font-family: AvenirBlack;
}

.Detox_FontMedium__2ndQ6 {
    font-family: AvenirMedium;
}

.Detox_BoxMenuProductos__1SQpW {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding: 0 26.5%;
    padding-bottom: 140px;
}

.Detox_BoxProducto__1qeCZ {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    min-height: 170px;
    cursor: pointer;
}

.Detox_BoxProducto__1qeCZ label {
    width: 100%;
    font-size: 16px;
    width: 65%;
    margin-bottom: 0;
    font-family: AvenirBook;
    text-align: center;
    cursor: pointer;
}

.Detox_BoxInfoProducto__1BXPs {
    width: 100%;
    height: 100%;
    padding-right: 13px;
    padding-left: 51px;
}

.Detox_DivButtonPreguntas__cmBtq button {
    outline: none;
    border: #ffffff solid 3px;
    background: #de0034 0% 0% no-repeat padding-box;
    border-radius: 16px;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-transform: uppercase;
    font-family: CocogooseLight;
    font-weight: bold;
    font-size: 12px;
    padding: 2% 16%;
    margin-left: 11%;
    margin-top: 54px;
}

.Detox_DivButtonPreguntas__cmBtq button:focus {
    outline: none;
}

.Detox_DivButtonPreguntas__cmBtq button:hover {
    background: transparent;
    border: 3px solid #de0034;
    color: #de0034;
}

.Detox_DivImagenes__x-luY {
    position: relative;
    width: 65%;
    float: left;
}

.Detox_BoxTxtDesripTea__3e0vd {
    position: absolute;
    width: 90%;
    top: -155px;
    right: 22px;
    padding: 11% 13% 14% 26%;

    display: -webkit-flex;

    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
}

.Detox_BoxTxtDesripTea__3e0vd img {
    cursor: pointer;
    position: absolute;
    width: 20px;
    height: 20px;
    top: -6%;
    right: 1%;
}

.Detox_BoxTxtDesripTea__3e0vd label {
    font-family: AvenirBlack;
}

.Detox_BoxTxtDesripTea__3e0vd text {
    font-family: AvenirBook;
}

.Detox_ContainerInformacionDetox__2Us12 {
    position: absolute;
    width: 96%;
    top: 7.5%;
    left: 2%;
    background: #FDFDF3 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    overflow: hidden;
    transition: height 0.5s ease, opacity 0.2s ease, margin 0.5s ease;
}

.Detox_ContainerInformacionDetox__2Us12 label {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    font-family: CocogooseProRegular;
    text-transform: uppercase;
    letter-spacing: 0px;
    color: #0A0A0A;
    cursor: pointer;
    font-size: 30px;
}

.Detox_BoxInformacionDetox__K869N {
    width: 94%;
    height: 90%;
    margin: 3%;
    position: relative;
}

.Detox_BoxInfoProductoDesk__2h0Xf,
.Detox_FloatingDescriptionDesk__3gS8H {
    display: inline;
}

.Detox_BoxInfoProductoIpad__FZkNQ,
.Detox_FloatingDescriptionIpad__12qi0 {
    display: none;
}

.Detox_BoxInfoProductoMobile__bDcWR,
.Detox_FloatingDescriptionMobile__12tXI {
    display: none;
}

.Detox_IconTePopup__2d_71 {
    width: 29px;
    height: 30px;
    margin-right: 17px;
}

.Detox_Container__6YtCj,
.Detox_ContainerBanner__eARtC {
    padding: 19px 26px;
}

.Detox_ContainerBanner__eARtC {
    padding-bottom: 0;
}

.Detox_IconCerrarPopupDetox__2nuhu {
    cursor: pointer;
    position: absolute;
    right: 18px;
    top: 19px;
}

.Detox_BoxPreguntas__158UH {
    width: 28%;
    float: left;
    height: 100%;
    position: relative;
    padding-top: 5.5%;
}

.Detox_ImagenDiapCSS__2Icv7 {
    margin-bottom: 67px;
}

.Detox_BoxTexto__111zF {
    width: 72%;
    float: left;
    height: 100%;
    letter-spacing: 0px;
    color: #000000;
    font-family: AvenirBook;
    font-size: 23px;
    padding: 3% 1% 3% 3%;
    transition: opacity 0.2s ease;
}

.Detox_EspaciadorBanner__15Ttw {
    height: 20px;
    width: 100%;
    display: inline-block;
}

.Detox_ContainerBanner__eARtC img {
    width: 100%;
    height: 698px;
}

.Detox_BoxProducto__1qeCZ img {
    width: 150px;
}

.Detox_DivPreguntas__3e4y1 {
    width: 35%;
    float: left;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.Detox_DivPreguntas__3e4y1 label {
    font-size: 30px;
    margin-bottom: 45px;
    font-family: CocogooseProRegular;
    text-transform: uppercase;
    letter-spacing: 0px;
    color: #0A0A0A;
    cursor: pointer;
}

.Detox_DivPreguntas__3e4y1 img {
    width: 29px;
    height: 30px;
    margin-right: 17px;
}

.Detox_DivImgProducto__26iP0 {
    width: 80%;
    position: absolute;
    right: 0;
    top: 120px;
}

.Detox_DivImgProductoImg__3e9ZH {
    width: 650px;
    cursor: pointer;
}

.Detox_DivDescripcionTe__3JWSX {
    background: #FDFDF3 0% 0% no-repeat padding-box;
    border-radius: 20px;
    letter-spacing: 0px;
    color: #000000;
    position: absolute;
    top: -200px;
    right: 0%;
    width: 92%;
    height: 380px;
}

.Detox_ImgAbs__3bUt4 {
    position: absolute;
    left: -62px;
    top: 33px;
    max-width: 415px;
}

.Detox_ImgAbs__3bUt4 img {
    max-width: 97%;
}

@media screen and (max-width: 1024px) {
    /* Ipad */
    .Detox_BoxInfoProductoDesk__2h0Xf,
    .Detox_FloatingDescriptionDesk__3gS8H {
        display: none;
    }

    .Detox_BoxInfoProductoIpad__FZkNQ,
    .Detox_FloatingDescriptionIpad__12qi0 {
        display: inline;
    }

    .Detox_BoxInfoProductoMobile__bDcWR,
    .Detox_FloatingDescriptionMobile__12tXI {
        display: none;
    }

    .Detox_BoxMenuProductos__1SQpW {
        padding: 2% 12.5% 32px;
    }

    .Detox_DivPreguntas__3e4y1 {
        width: 100%;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        padding-left: 26%;
        margin-top: 16px;
    }

    .Detox_DivPreguntas__3e4y1 label {
        font-size: 45px;
        margin-bottom: 15px;
    }

    .Detox_DivPreguntas__3e4y1 img {
        width: 40px;
        height: 40px;
        margin-right: 14px;
    }

    .Detox_Container__6YtCj {
        padding: 3% 2%;
    }

    .Detox_BoxTexto__111zF,
    .Detox_BoxPreguntas__158UH {
        width: 100%;
        height: auto;
    }

    .Detox_BoxTexto__111zF { padding: 3% 2%; }
    .Detox_BoxPreguntas__158UH { padding-top: 0;}

    .Detox_DivImgTitInfDetox__23u0w {
        text-align: center;
        margin-bottom: 10px;
        margin-top: 8%;
    }

    .Detox_DivImgTitInfDetox__23u0w img {
        width: 60%;
        max-height: 102px;
    }

    .Detox_IconTePopup__2d_71 {
        width: 40px;
        height: 40px;
        margin-right: 15px;
    }

    .Detox_EspaciadorBanner__15Ttw {
        height: 10vw;
        width: 100%;
        display: inline-block;
    }

    .Detox_ContainerInformacionDetox__2Us12 {
        position: relative;
        width: 96%;
    }

    .Detox_ContainerInformacionDetox__2Us12 label {
        font-size: 45px;
    }

    .Detox_ImgPlanDetox2__311Yy {
        width: 50%;
    }

    .Detox_ImagenDiapCSS__2Icv7 {
        margin-bottom: 0;
    }

    .Detox_ContainerBanner__eARtC {
        padding: 0;
    }

    .Detox_ContainerBanner__eARtC img {
        width: 100%;
        height: 470px;
    }

    .Detox_ContainerMenuDiapMob__1wMem {
        padding-left: 25%;
        margin-top: 24px;
    }

    .Detox_IconCerrarPopupDetox__2nuhu {
        cursor: pointer;
        position: absolute;
        right: 30px;
        margin-top: 1.5%;
        width: 24px;
        z-index: 2;
    }

    .Detox_TitleSection__1rs47 {
        text-align: left;
        font-size: 35px;
        line-height: 1.15;
    }

    .Detox_BoxProducto__1qeCZ {
        min-height: 93px;
    }

    .Detox_BoxProducto__1qeCZ img {
        width: 150px;
    }

    .Detox_BoxProducto__1qeCZ label {
        width: 100%;
        margin-bottom: 0;
    }

    .Detox_BoxTexto__111zF {
        font-size: 26px;
    }

    .Detox_BoxInfoProducto__1BXPs {
        padding: 0;
        padding-top: 0;
    }

    .Detox_DivImagenes__x-luY {
        width: 100%;
    }

    .Detox_DivButtonPreguntas__cmBtq button {
        font-size: 26px;
        border-radius: 40px;
        padding: 2.2% 16%;
        margin-top: 5%;
        margin-left: 7%;
    }

    .Detox_DivImgProducto__26iP0 {
        position: relative;
        left: 11%;
        top: 0;
    }

    .Detox_DivImgProductoImg__3e9ZH {
        width: 100%;
        height: auto;
    }

    .Detox_ImgAbs__3bUt4 {
        max-width: 400px;
        position: absolute;
        left: -73px;
        top: 35%;
    }

    .Detox_ImgAbs__3bUt4 img {
        width: 95%;
    }

    .Detox_BoxTxtDesripTea__3e0vd {
        width: 80%;
        position: absolute;
        height: 70%;
        top: 14%;
        right: 10%;
        padding: 7%;
        font-size: 26px;
    }

    .Detox_BoxTxtDesripTea__3e0vd img {
        top: 20px;
        right: 18px;
    }

    .Detox_DivDescripcionTe__3JWSX {
        background: #FDFDF3 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 12px;
        height: 100%;
        letter-spacing: 0px;
        color: #000000;
        position: absolute;
        top: 14%;
        right: 10%;
        width: 80%;
        height: 70%;
    }
}

@media screen and (max-width: 600px),
(max-height: 325px) {

    /* Mobile */
    .Detox_BoxInfoProductoDesk__2h0Xf,
    .Detox_FloatingDescriptionDesk__3gS8H {
        display: none;
    }

    .Detox_BoxInfoProductoIpad__FZkNQ,
    .Detox_FloatingDescriptionIpad__12qi0 {
        display: none;
    }

    .Detox_BoxInfoProductoMobile__bDcWR,
    .Detox_FloatingDescriptionMobile__12tXI {
        display: inline;
    }

    .Detox_BoxMenuProductos__1SQpW {
        padding: 0 30px;
    }

    .Detox_ContainerBanner__eARtC img {
        height: 201px;
    }

    .Detox_BoxProducto__1qeCZ img {
        width: 72px;
    }

    .Detox_ImgAbs__3bUt4 {
        max-width: 129px;
        left: -15px;
        top: 49%;
    }

    .Detox_Container__6YtCj {
        padding: 7px 9px;
    }

    .Detox_BoxProducto__1qeCZ label {
        font-size: 10px;
    }

    .Detox_BoxTxtDesripTea__3e0vd {
        font-size: 12px;
    }

    .Detox_BoxTxtDesripTea__3e0vd img {
        width: 10px;
        height: 10px;
        top: 16px;
        right: 14px;
    }

    .Detox_DivPreguntas__3e4y1 {
        padding-left: 21%;
    }

    .Detox_DivPreguntas__3e4y1 label {
        font-size: 19px;
    }

    .Detox_DivPreguntas__3e4y1 img {
        width: 20px;
        height: 20px;
    }

    .Detox_DivButtonPreguntas__cmBtq button {
        border-radius: 16px;
        font-size: 10px;
        margin-left: 10%;
    }

    .Detox_BoxTexto__111zF {
        font-size: 12px;
    }

    .Detox_DivImgTitInfDetox__23u0w img {
        max-height: 52px;
        width: 75%;
    }

    .Detox_IconCerrarPopupDetox__2nuhu {
        width: 9px;
        right: 12px;
        margin-top: -2.5%;
    }

    .Detox_ContainerInformacionDetox__2Us12 label {
        font-size: 19px;
    }

    .Detox_IconTePopup__2d_71 {
        width: 20px;
        height: 20px;
    }

    .Detox_ContainerMenuDiapMob__1wMem {
        padding-left: 20%;
    }
}

.TeaAnimation_TeaAnimation__TetdK{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
}

.TeaAnimation_wrapImgTaza__1cyNd{
    position: absolute;
    left: 49.5%;
    top: 50%;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
    width: 100%;
    padding-bottom: 54%;
    height: 0px;
    
}

.TeaAnimation_ImgTaza__24dQL {
    width: 100%;
}

.TeaAnimation_DivPolygon__smR0S {
    overflow: hidden;
    width: 100%;
    height: 0;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
    padding-bottom: 54.95%;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-clip-path: polygon(47% 4%, 64% 4%, 77% 7%, 71% 49%, 63% 72%, 49% 75%, 36% 74%, 27% 48%, 21% 9%, 32% 5%);
            clip-path: polygon(47% 4%, 64% 4%, 77% 7%, 71% 49%, 63% 72%, 49% 75%, 36% 74%, 27% 48%, 21% 9%, 32% 5%);
}


.TeaAnimation_circuloBg__ZljrY{
    width: 200%;
    border-radius: 47%;
    height: 0;
    position: absolute;
    top: -347%;
    left: -50%;
    padding-bottom: 200%;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-animation: TeaAnimation_liquid__1CRs8 12s infinite linear;
            animation: TeaAnimation_liquid__1CRs8 12s infinite linear
}

.TeaAnimation_circuloRelleno__1tl2B{
    width: 200%;
    border-radius: 47%;
    height: 0;
    position: absolute;
    top: -349%;
    left: -50%;
    padding-bottom: 200%;
    background-color: white;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-animation: TeaAnimation_liquid__1CRs8 15s infinite linear;
            animation: TeaAnimation_liquid__1CRs8 15s infinite linear
}

@-webkit-keyframes TeaAnimation_liquid__1CRs8 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(365deg);
            transform: rotate(365deg);
  }
}

@keyframes TeaAnimation_liquid__1CRs8 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(365deg);
            transform: rotate(365deg);
  }
}





.ButtonLiquid-Container {
    position: absolute;
    cursor: pointer;
    height: 140px;
    width: 140px;
    right: 7%;
    bottom: 122px;
    overflow: hidden;
    background: transparent;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.ButtonLiquid-SimbolMas {
    position: absolute;
    background: transparent;
    width: 6px;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(0, -30%);
            transform: translate(0, -30%);
}

.ButtonLiquid-SimbolMas img {
    width: 100%;
    -webkit-animation: ball-queen 21s cubic-bezier(.32,2,.59,2) infinite;
            animation: ball-queen 21s cubic-bezier(.32,2,.59,2) infinite;
}

.ButtonLiquid-Layer {
    height: 100%;
    width: 100%;
    -webkit-filter: contrast(10);
            filter: contrast(10);
 }

.ButtonLiquid-Balls {
    background: #fff;
    position: absolute;
    width: 100%;
    height: 100%;

    -webkit-filter: blur(5px);

            filter: blur(5px);
}

.ButtonLiquid-Button {
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -30%) scale(1);
            transform: translate(-50%, -30%) scale(1);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: absolute;
    z-index: 100;
    -webkit-filter: blur(2px);
            filter: blur(2px);
    -webkit-animation: ball-queen 21s cubic-bezier(.32,2,.59,2) infinite;
            animation: ball-queen 21s cubic-bezier(.32,2,.59,2) infinite;
}

.ButtonLiquid-ball {
    border-radius: 50%;
    width: 15px;
    height: 15px;
    position: absolute;
    will-change: transform;
}

.ButtonLiquid-ball-1, .ButtonLiquid-ball-2, .ButtonLiquid-ball-3, .ButtonLiquid-ball-4 {
    z-index: 2;
}

.ButtonLiquid-ball-1 {
    top: 0;
    left: 0;
    -webkit-animation: ball-1 1.05s infinite cubic-bezier(.4,.62,.57,.98);
            animation: ball-1 1.05s infinite cubic-bezier(.4,.62,.57,.98);
}

@-webkit-keyframes ball-1 {
    to { -webkit-transform: translate(4.5vw, 11vh ); transform: translate(4.5vw, 11vh ); }
}

@keyframes ball-1 {
    to { -webkit-transform: translate(4.5vw, 11vh ); transform: translate(4.5vw, 11vh ); }
}

.ButtonLiquid-ball-2 {
    top: 0;
    right: 0;
    -webkit-animation: ball-2 1.05s .05s infinite cubic-bezier(.4,.62,.57,.98);
            animation: ball-2 1.05s .05s infinite cubic-bezier(.4,.62,.57,.98);
}

@-webkit-keyframes ball-2 {
    to { -webkit-transform: translate(-4.5vw, 11vh ); transform: translate(-4.5vw, 11vh ); }
}

@keyframes ball-2 {
    to { -webkit-transform: translate(-4.5vw, 11vh ); transform: translate(-4.5vw, 11vh ); }
}

.ButtonLiquid-ball-3 {
    bottom: 0;
    right: 0;
    -webkit-animation: ball-3 1.05s .1s infinite cubic-bezier(.4,.62,.57,.98);
            animation: ball-3 1.05s .1s infinite cubic-bezier(.4,.62,.57,.98);
}

@-webkit-keyframes ball-3 {
    to { -webkit-transform: translate(-5vw, -8vh); transform: translate(-5vw, -8vh); }  
}

@keyframes ball-3 {
    to { -webkit-transform: translate(-5vw, -8vh); transform: translate(-5vw, -8vh); }  
}

.ButtonLiquid-ball-4 {
    bottom: 0;
    left: 0;
    -webkit-animation: ball-4 1.05s .15s infinite cubic-bezier(.4,.62,.57,.98);
            animation: ball-4 1.05s .15s infinite cubic-bezier(.4,.62,.57,.98);
}

@-webkit-keyframes ball-4 {
    100% { -webkit-transform: translate(5vw, -8vh ); transform: translate(5vw, -8vh ); }
}

@keyframes ball-4 {
    100% { -webkit-transform: translate(5vw, -8vh ); transform: translate(5vw, -8vh ); }
}

@-webkit-keyframes ball-queen {
    
    0%, 3%   { -webkit-transform: translate(-50%, -30%) rotate(0deg)  scale(1.1); transform: translate(-50%, -30%) rotate(0deg)  scale(1.1) }
    4%, 8%   { -webkit-transform: translate(-50%, -30%) rotate(0deg)  scale(1.2); transform: translate(-50%, -30%) rotate(0deg)  scale(1.2) }
    9%, 13%  { -webkit-transform: translate(-50%, -30%) rotate(0deg)  scale(1.3); transform: translate(-50%, -30%) rotate(0deg)  scale(1.3) }
    14%, 18% { -webkit-transform: translate(-50%, -30%) rotate(0deg)  scale(1.4); transform: translate(-50%, -30%) rotate(0deg)  scale(1.4) }
    19%, 23% { -webkit-transform: translate(-50%, -30%) rotate(0deg)  scale(1.5); transform: translate(-50%, -30%) rotate(0deg)  scale(1.5) }
    24%, 28% { -webkit-transform: translate(-50%, -30%) rotate(0deg)  scale(1.6); transform: translate(-50%, -30%) rotate(0deg)  scale(1.6) }
    29%, 33% { -webkit-transform: translate(-50%, -30%) rotate(0deg)  scale(1.7); transform: translate(-50%, -30%) rotate(0deg)  scale(1.7) }
    34%, 38% { -webkit-transform: translate(-50%, -30%) rotate(0deg)  scale(1.8); transform: translate(-50%, -30%) rotate(0deg)  scale(1.8) }
    39%, 43% { -webkit-transform: translate(-50%, -30%) rotate(0deg)  scale(1.9); transform: translate(-50%, -30%) rotate(0deg)  scale(1.9) }
    44%, 48% { -webkit-transform: translate(-50%, -30%) rotate(0deg)  scale(2  ); transform: translate(-50%, -30%) rotate(0deg)  scale(2  ) }
    49%, 53% { -webkit-transform: translate(-50%, -30%) rotate(0deg)  scale(2.1); transform: translate(-50%, -30%) rotate(0deg)  scale(2.1) }
    54%, 58% { -webkit-transform: translate(-50%, -30%) rotate(0deg)  scale(2.2); transform: translate(-50%, -30%) rotate(0deg)  scale(2.2) }
    59%, 63% { -webkit-transform: translate(-50%, -30%) rotate(0deg)  scale(2.3); transform: translate(-50%, -30%) rotate(0deg)  scale(2.3) }
    64%, 68% { -webkit-transform: translate(-50%, -30%) rotate(0deg)  scale(2.4); transform: translate(-50%, -30%) rotate(0deg)  scale(2.4) }
    69%, 73% { -webkit-transform: translate(-50%, -30%) rotate(0deg)  scale(2.5); transform: translate(-50%, -30%) rotate(0deg)  scale(2.5) }
    74%, 78% { -webkit-transform: translate(-50%, -30%) rotate(0deg)  scale(2.6); transform: translate(-50%, -30%) rotate(0deg)  scale(2.6) }
    79%, 83% { -webkit-transform: translate(-50%, -30%) rotate(0deg)  scale(2.7); transform: translate(-50%, -30%) rotate(0deg)  scale(2.7) }
    84%, 88% { -webkit-transform: translate(-50%, -30%) rotate(0deg)  scale(2.8); transform: translate(-50%, -30%) rotate(0deg)  scale(2.8) }
    89%, 93% { -webkit-transform: translate(-50%, -30%) rotate(0deg)  scale(2.9); transform: translate(-50%, -30%) rotate(0deg)  scale(2.9) }
    94%, 98% { -webkit-transform: translate(-50%, -30%) rotate(0deg)  scale(3  ); transform: translate(-50%, -30%) rotate(0deg)  scale(3  ) } 
}

@keyframes ball-queen {
    
    0%, 3%   { -webkit-transform: translate(-50%, -30%) rotate(0deg)  scale(1.1); transform: translate(-50%, -30%) rotate(0deg)  scale(1.1) }
    4%, 8%   { -webkit-transform: translate(-50%, -30%) rotate(0deg)  scale(1.2); transform: translate(-50%, -30%) rotate(0deg)  scale(1.2) }
    9%, 13%  { -webkit-transform: translate(-50%, -30%) rotate(0deg)  scale(1.3); transform: translate(-50%, -30%) rotate(0deg)  scale(1.3) }
    14%, 18% { -webkit-transform: translate(-50%, -30%) rotate(0deg)  scale(1.4); transform: translate(-50%, -30%) rotate(0deg)  scale(1.4) }
    19%, 23% { -webkit-transform: translate(-50%, -30%) rotate(0deg)  scale(1.5); transform: translate(-50%, -30%) rotate(0deg)  scale(1.5) }
    24%, 28% { -webkit-transform: translate(-50%, -30%) rotate(0deg)  scale(1.6); transform: translate(-50%, -30%) rotate(0deg)  scale(1.6) }
    29%, 33% { -webkit-transform: translate(-50%, -30%) rotate(0deg)  scale(1.7); transform: translate(-50%, -30%) rotate(0deg)  scale(1.7) }
    34%, 38% { -webkit-transform: translate(-50%, -30%) rotate(0deg)  scale(1.8); transform: translate(-50%, -30%) rotate(0deg)  scale(1.8) }
    39%, 43% { -webkit-transform: translate(-50%, -30%) rotate(0deg)  scale(1.9); transform: translate(-50%, -30%) rotate(0deg)  scale(1.9) }
    44%, 48% { -webkit-transform: translate(-50%, -30%) rotate(0deg)  scale(2  ); transform: translate(-50%, -30%) rotate(0deg)  scale(2  ) }
    49%, 53% { -webkit-transform: translate(-50%, -30%) rotate(0deg)  scale(2.1); transform: translate(-50%, -30%) rotate(0deg)  scale(2.1) }
    54%, 58% { -webkit-transform: translate(-50%, -30%) rotate(0deg)  scale(2.2); transform: translate(-50%, -30%) rotate(0deg)  scale(2.2) }
    59%, 63% { -webkit-transform: translate(-50%, -30%) rotate(0deg)  scale(2.3); transform: translate(-50%, -30%) rotate(0deg)  scale(2.3) }
    64%, 68% { -webkit-transform: translate(-50%, -30%) rotate(0deg)  scale(2.4); transform: translate(-50%, -30%) rotate(0deg)  scale(2.4) }
    69%, 73% { -webkit-transform: translate(-50%, -30%) rotate(0deg)  scale(2.5); transform: translate(-50%, -30%) rotate(0deg)  scale(2.5) }
    74%, 78% { -webkit-transform: translate(-50%, -30%) rotate(0deg)  scale(2.6); transform: translate(-50%, -30%) rotate(0deg)  scale(2.6) }
    79%, 83% { -webkit-transform: translate(-50%, -30%) rotate(0deg)  scale(2.7); transform: translate(-50%, -30%) rotate(0deg)  scale(2.7) }
    84%, 88% { -webkit-transform: translate(-50%, -30%) rotate(0deg)  scale(2.8); transform: translate(-50%, -30%) rotate(0deg)  scale(2.8) }
    89%, 93% { -webkit-transform: translate(-50%, -30%) rotate(0deg)  scale(2.9); transform: translate(-50%, -30%) rotate(0deg)  scale(2.9) }
    94%, 98% { -webkit-transform: translate(-50%, -30%) rotate(0deg)  scale(3  ); transform: translate(-50%, -30%) rotate(0deg)  scale(3  ) } 
}

@media screen and (max-width: 1024px) {
    /* Ipad */

    .ButtonLiquid-Container {
        right: 86%;
        bottom: 270px;
        z-index: 100;
    }

    .ButtonLiquid-Button {
        width: 35px;
        height: 35px;
    }

    .ButtonLiquid-ball {
        width: 18px;
        height: 18px;
    }

    .ButtonLiquid-SimbolMas {
        width: 12px;
    }

    @-webkit-keyframes ball-1 {
        to { -webkit-transform: translate(7vw, 6vh ); transform: translate(7vw, 6vh ); }
    }

    @keyframes ball-1 {
        to { -webkit-transform: translate(7vw, 6vh ); transform: translate(7vw, 6vh ); }
    }

    @-webkit-keyframes ball-2 {
        to { -webkit-transform: translate(-7vw, 6vh ); transform: translate(-7vw, 6vh ); }
    }

    @keyframes ball-2 {
        to { -webkit-transform: translate(-7vw, 6vh ); transform: translate(-7vw, 6vh ); }
    }

    @-webkit-keyframes ball-3 {
        to { -webkit-transform: translate(-7vw, -4vh ); transform: translate(-7vw, -4vh ); }
    }

    @keyframes ball-3 {
        to { -webkit-transform: translate(-7vw, -4vh ); transform: translate(-7vw, -4vh ); }
    }

    @-webkit-keyframes ball-4 {
        100% { -webkit-transform: translate(7vw, -4vh ); transform: translate(7vw, -4vh ); }
    }

    @keyframes ball-4 {
        100% { -webkit-transform: translate(7vw, -4vh ); transform: translate(7vw, -4vh ); }
    }

}

@media screen and (max-width: 600px), (max-height: 325px) {
   /* Mobile */
    .ButtonLiquid-Container {
        height: 90px;
        width: 90px;
        right: 80%;
        bottom: 90px;
    }

    .ButtonLiquid-Button {
        width: 15px;
        height: 15px;
    }

    .ButtonLiquid-Balls {
        -webkit-filter: blur(3px);
                filter: blur(3px);
    }

    .ButtonLiquid-ball {
        width: 10px;
        height: 10px;
    }

    .ButtonLiquid-SimbolMas {
        width: 6px;
    }

    @-webkit-keyframes ball-1 {
        to { -webkit-transform: translate(10vw, 7vh ); transform: translate(10vw, 7vh ); }
    }

    @keyframes ball-1 {
        to { -webkit-transform: translate(10vw, 7vh ); transform: translate(10vw, 7vh ); }
    }

    @-webkit-keyframes ball-2 {
        to { -webkit-transform: translate(-10vw, 7vh ); transform: translate(-10vw, 7vh ); }
    }

    @keyframes ball-2 {
        to { -webkit-transform: translate(-10vw, 7vh ); transform: translate(-10vw, 7vh ); }
    }

    @-webkit-keyframes ball-3 {
        to { -webkit-transform: translate(-10vw, -5vh ); transform: translate(-10vw, -5vh ); }
    }

    @keyframes ball-3 {
        to { -webkit-transform: translate(-10vw, -5vh ); transform: translate(-10vw, -5vh ); }
    }

    @-webkit-keyframes ball-4 {
        100% { -webkit-transform: translate(10vw, -5vh ); transform: translate(10vw, -5vh ); }
    }

    @keyframes ball-4 {
        100% { -webkit-transform: translate(10vw, -5vh ); transform: translate(10vw, -5vh ); }
    }
}
