.info-productos{
    font-family: CocogooseProRegular;
    width: 80%;
    z-index: 10;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    position: absolute;
    text-align: center;
}
.MS-ENERGA {
    
    text-shadow: 4px 8px 16px rgba(0, 0, 0, 0.59);
    font-family: CocogooseProRegular;
    font-size: 90px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.94;
    letter-spacing: 2.14px;
    text-align: center;
    color: #ffffff;
  }

  .El-sabor-tradiciona {
    
  
    font-family: SignPainter;
    font-size: 42px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.52;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }
  .filtro-productos{
    font-family:AvenirBook;
    background: #ffffff;
    background: #ffffff;
    box-shadow: 2px 2px 10px #666;  
    position: absolute;
    transform: translate(-50%, -50%);
    bottom:-10%;
    left: 50%;
    z-index: 1;
    width: 80%;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px
}
.QUIERO-VER {
    font-family: CocogooseProRegular;
    font-size: 16px;
    margin: 0px;
    margin-right: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.88;
    letter-spacing: 2.5px;
    text-align: center;
    color: #000000;
  }

  .linea-guia{
      position: fixed;
      height: 100%;
      width: 2px;
      background:red;
      margin-left: 100px;
      z-index: 89;
  }
  .NUESTROS-PRODUCTOS {
    margin-top: 10%;
    margin-left: 100px;
    font-family: CocogooseProRegular;
    font-size: 48px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: -1px;
    color: #000000;
  }
  .undefined {
    margin-left: 100px;
    font-family: AvenirBook;
    font-size: 26px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.55;
    letter-spacing: normal;
    color: #000000;
  }

  .info-categorias-productos {
    z-index: 10;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    top: 30%;
    left:30%;
    position: absolute;
}
.product-hover{
  transition:  .2s 
  

}
.product-hover:hover{
  transform: scale(1.1) ;
transition: 0.5s
  

}


.option-class{
  background: #ffd766
}
.option-class:hover{
  border: 4px solid #ffd766 !important;
}
.productos-mobile{
  display: none;

}
.productos-desk{
  display: block;
}

@media screen and (max-width: 687px), (max-height: 325px) {
  .productos-mobile{
    display: block;
  }
  .productos-desk{
    display: none;
  }

  .filtro-productos{
    font-family:AvenirBook;
    background: #ffffff;    
    box-shadow: 2px 2px 10px #666;  
    position: absolute;
    transform: translate(-50%, -50%);
    bottom:-52%;
    left: 50%; 
    z-index: 1;
    width: 95%;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px
    
}

.filtro-productos-center{
  text-align: center;
  text-align: -webkit-center;
  background-color: #ffffff;

}

.NUESTROS-PRODUCTOS{

  font-family: CocogooseProRegular;
    font-size: 4.4vw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: -1px;
    color: #000000;
    text-align: center;
    margin-left: 20px;
    margin-top: 10%;
}

.mayonesas{
  font-size: 16px;
  color: rgb(212, 18, 70);
  font-family:CocogooseProRegular;
  margin-left: 2%;
  margin-top: 7%;
}

.mayosub{

    font-family: CocogooseProRegular;
    font-size: 4.4vw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: 3px;
    color: #000000;
    text-align: left;
    margin-left: 10px;
}

.undefined {
  margin-left: 23px;
  font-family: AvenirBook;
  font-size: 3vw;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.55;
  letter-spacing: normal;
  color: #000000;
}

.info-categorias-productos {
  z-index: 10;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  top: 30%;
  left:50%;
  position: absolute;
}

}
