.ButtonLiquid-Container {
    position: absolute;
    cursor: pointer;
    height: 140px;
    width: 140px;
    right: 7%;
    bottom: 122px;
    overflow: hidden;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ButtonLiquid-SimbolMas {
    position: absolute;
    background: transparent;
    width: 6px;
    top: 50%;
    left: 50%;
    transform: translate(0, -30%);
}

.ButtonLiquid-SimbolMas img {
    width: 100%;
    animation: ball-queen 21s cubic-bezier(.32,2,.59,2) infinite;
}

.ButtonLiquid-Layer {
    height: 100%;
    width: 100%;
    filter: contrast(10);
 }

.ButtonLiquid-Balls {
    background: #fff;
    position: absolute;
    width: 100%;
    height: 100%;

    filter: blur(5px);
}

.ButtonLiquid-Button {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -30%) scale(1);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: absolute;
    z-index: 100;
    filter: blur(2px);
    animation: ball-queen 21s cubic-bezier(.32,2,.59,2) infinite;
}

.ButtonLiquid-ball {
    border-radius: 50%;
    width: 15px;
    height: 15px;
    position: absolute;
    will-change: transform;
}

.ButtonLiquid-ball-1, .ButtonLiquid-ball-2, .ButtonLiquid-ball-3, .ButtonLiquid-ball-4 {
    z-index: 2;
}

.ButtonLiquid-ball-1 {
    top: 0;
    left: 0;
    animation: ball-1 1.05s infinite cubic-bezier(.4,.62,.57,.98);
}

@keyframes ball-1 {
    to { transform: translate(4.5vw, 11vh ); }
}

.ButtonLiquid-ball-2 {
    top: 0;
    right: 0;
    animation: ball-2 1.05s .05s infinite cubic-bezier(.4,.62,.57,.98);
}

@keyframes ball-2 {
    to { transform: translate(-4.5vw, 11vh ); }
}

.ButtonLiquid-ball-3 {
    bottom: 0;
    right: 0;
    animation: ball-3 1.05s .1s infinite cubic-bezier(.4,.62,.57,.98);
}

@keyframes ball-3 {
    to { transform: translate(-5vw, -8vh); }  
}

.ButtonLiquid-ball-4 {
    bottom: 0;
    left: 0;
    animation: ball-4 1.05s .15s infinite cubic-bezier(.4,.62,.57,.98);
}

@keyframes ball-4 {
    100% { transform: translate(5vw, -8vh ); }
}

@keyframes ball-queen {
    
    0%, 3%   { transform: translate(-50%, -30%) rotate(0deg)  scale(1.1) }
    4%, 8%   { transform: translate(-50%, -30%) rotate(0deg)  scale(1.2) }
    9%, 13%  { transform: translate(-50%, -30%) rotate(0deg)  scale(1.3) }
    14%, 18% { transform: translate(-50%, -30%) rotate(0deg)  scale(1.4) }
    19%, 23% { transform: translate(-50%, -30%) rotate(0deg)  scale(1.5) }
    24%, 28% { transform: translate(-50%, -30%) rotate(0deg)  scale(1.6) }
    29%, 33% { transform: translate(-50%, -30%) rotate(0deg)  scale(1.7) }
    34%, 38% { transform: translate(-50%, -30%) rotate(0deg)  scale(1.8) }
    39%, 43% { transform: translate(-50%, -30%) rotate(0deg)  scale(1.9) }
    44%, 48% { transform: translate(-50%, -30%) rotate(0deg)  scale(2  ) }
    49%, 53% { transform: translate(-50%, -30%) rotate(0deg)  scale(2.1) }
    54%, 58% { transform: translate(-50%, -30%) rotate(0deg)  scale(2.2) }
    59%, 63% { transform: translate(-50%, -30%) rotate(0deg)  scale(2.3) }
    64%, 68% { transform: translate(-50%, -30%) rotate(0deg)  scale(2.4) }
    69%, 73% { transform: translate(-50%, -30%) rotate(0deg)  scale(2.5) }
    74%, 78% { transform: translate(-50%, -30%) rotate(0deg)  scale(2.6) }
    79%, 83% { transform: translate(-50%, -30%) rotate(0deg)  scale(2.7) }
    84%, 88% { transform: translate(-50%, -30%) rotate(0deg)  scale(2.8) }
    89%, 93% { transform: translate(-50%, -30%) rotate(0deg)  scale(2.9) }
    94%, 98% { transform: translate(-50%, -30%) rotate(0deg)  scale(3  ) } 
}

@media screen and (max-width: 1024px) {
    /* Ipad */

    .ButtonLiquid-Container {
        right: 86%;
        bottom: 270px;
        z-index: 100;
    }

    .ButtonLiquid-Button {
        width: 35px;
        height: 35px;
    }

    .ButtonLiquid-ball {
        width: 18px;
        height: 18px;
    }

    .ButtonLiquid-SimbolMas {
        width: 12px;
    }

    @keyframes ball-1 {
        to { transform: translate(7vw, 6vh ); }
    }

    @keyframes ball-2 {
        to { transform: translate(-7vw, 6vh ); }
    }

    @keyframes ball-3 {
        to { transform: translate(-7vw, -4vh ); }
    }

    @keyframes ball-4 {
        100% { transform: translate(7vw, -4vh ); }
    }

}

@media screen and (max-width: 600px), (max-height: 325px) {
   /* Mobile */
    .ButtonLiquid-Container {
        height: 90px;
        width: 90px;
        right: 80%;
        bottom: 90px;
    }

    .ButtonLiquid-Button {
        width: 15px;
        height: 15px;
    }

    .ButtonLiquid-Balls {
        filter: blur(3px);
    }

    .ButtonLiquid-ball {
        width: 10px;
        height: 10px;
    }

    .ButtonLiquid-SimbolMas {
        width: 6px;
    }

    @keyframes ball-1 {
        to { transform: translate(10vw, 7vh ); }
    }

    @keyframes ball-2 {
        to { transform: translate(-10vw, 7vh ); }
    }

    @keyframes ball-3 {
        to { transform: translate(-10vw, -5vh ); }
    }

    @keyframes ball-4 {
        100% { transform: translate(10vw, -5vh ); }
    }
}