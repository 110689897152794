.App{

    min-height:100vh;
    min-width: 100%;
  
    position: relative;

}

.custom-class{
position: absolute;
z-index: 50;
}
.content::-webkit-scrollbar:vertical { display: none}
::-webkit-scrollbar {
    width: 12px;
    height: 12px;
}

button{
    outline: none !important
}

.content::-webkit-scrollbar-track:horizontal {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    background-color: #ffffff;
    border-radius: 10px;
}
.content::-webkit-scrollbar-thumb{
background: rgba(200,200,200,1)  !important;
}
body::-webkit-scrollbar-thumb{
    background: rgba(200,200,200,1)  !important;
    }
 
.content::-webkit-scrollbar-thumb:horizontal {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
    background-color: #ffffff;
}
 
body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    border-radius: 10px;
    background-color: #ffffff;
}

body::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
    background-color: #ffffff;
}
div::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
    background-color:#ff0000;
}
div::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    border-radius: 10px;
    background-color:#ffffff;
}
@media screen and (max-width: 687px), (max-height: 325px) {

  
}
.hvr-float-shadow:hover {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
}
.hvr-float-shadow {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
  
    position: relative;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;
}
.card-container{
    margin-top: 20px;
    display: flex;
    justify-content: center;
    cursor: pointer;
}
