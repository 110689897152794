body {
  margin: 0;
  padding: 0;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: overlay;
 
  overflow-x: hidden;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin-bottom: 0.5rem;
  font-weight: bold;
  line-height: 1.2;
}
#root{
  max-width: 1440px;
  margin: auto;
}
pre img{
  width: 15vw !important;
  margin: 1px 20px 1px 20px;
}
pre p{
 margin-bottom: 5px;
}
pre{
white-space: inherit !important;
font-size: 18px !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@font-face {
  /*negrita*/
  font-family:CocogooseProRegular;
  src: url(./fuentes/Cocogoose-Pro-Regular-OTF.otf);
}

@font-face {
  /*delgadita*/
  font-family:CocogooseUltraLight;
  src: url(./fuentes/Cocogoose-Pro-Ultralight.ttf);
}

@font-face {
  /*normal*/
  font-family:CocogooseLight;
  src: url(./fuentes/Cocogoose-Pro-Light.ttf);
}

@font-face {
  font-family:SignPainter;
  src: url(./fuentes/SignPainter-HouseScript.otf);
}
@font-face {
  font-family: AvenirBlack;
  src: url(./fuentes/Avenir-Black.otf);
  
}
@font-face {
  font-family: AvenirBook;
  src: url(./fuentes/AvenirLTStd-Book.otf);
  
}
@font-face {
  font-family: AvenirNextLTProMedium;
  src: url(./fuentes/AvenirNextLTPro-MediumCnIt.otf);
  
}

@font-face {
  font-family: AvenirMedium;
  src: url(./fuentes/AvenirMedium.ttf);
}

@font-face {
  font-family: RobotoMedium;
  src: url(./fuentes/roboto-medium.ttf);
}