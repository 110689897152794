
.TeaAnimation{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}

.wrapImgTaza{
    position: absolute;
    left: 49.5%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
    padding-bottom: 54%;
    height: 0px;
    
}

.ImgTaza {
    width: 100%;
}

.DivPolygon {
    overflow: hidden;
    width: 100%;
    height: 0;
    transform: translate(-50%,-50%);
    padding-bottom: 54.95%;
    position: absolute;
    left: 50%;
    top: 50%;
    clip-path: polygon(47% 4%, 64% 4%, 77% 7%, 71% 49%, 63% 72%, 49% 75%, 36% 74%, 27% 48%, 21% 9%, 32% 5%);
}


.circuloBg{
    width: 200%;
    border-radius: 47%;
    height: 0;
    position: absolute;
    top: -347%;
    left: -50%;
    padding-bottom: 200%;
    transform: rotate(0deg);
    animation: liquid 12s infinite linear
}

.circuloRelleno{
    width: 200%;
    border-radius: 47%;
    height: 0;
    position: absolute;
    top: -349%;
    left: -50%;
    padding-bottom: 200%;
    background-color: white;
    transform: rotate(0deg);
    animation: liquid 15s infinite linear
}

@keyframes liquid {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(365deg);
  }
}




