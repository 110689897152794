.menu-desk {
  background: #ffffff;
  position:fixed;
  top: 0px;
  left: 50%;
  display:flex;
  justify-content:space-between;
  align-items:center;
  z-index: 51;
  width: 100%;
  padding-left: 2%;
  padding-right: 1%;
  height: 60px;
  max-width: 1440px;
  transform: translate(-50%, 0);
}
.item-hover-product{

  font-family:AvenirBlack;
  font-size: 40px;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  color: #000000;
  cursor: pointer;
}
.my-collapse{
 right: 5% !important;
 left: 44% !important; 
}
.item---menu{
  height: 100%;
  padding: 12px;
  font-size: 20px;
  border-bottom: 5px solid transparent;
  color: black;
  cursor: pointer;
}
.logo-header {
    width: 196px;
    height: 42px;
    cursor: pointer;
  }

.menu-mobile{
  display: none
}
@media screen and (max-width: 687px), (max-height: 325px) {
  .menu-desk{
    display: none
  }

  .menu-mobile {
    display: flex;
    justify-content: flex-end;
    height: 49px;
    padding: 10px;
    position: fixed;
    top: 0px;
    width: 100%;
    background: white;
    z-index: 13;
  }

  .logo-header {
    width: 122px;
    height: 26px;
    position: absolute;
    left: 20px;
    margin-top: 4px;
  }

}