.TitleSection {
    font-family: CocogooseProRegular;
    text-transform: uppercase;
    letter-spacing: 0px;
    color: #0A0A0A;
    text-align: center;
    font-size: 50px;
}

.FontBlack {
    font-family: AvenirBlack;
}

.FontMedium {
    font-family: AvenirMedium;
}

.BoxMenuProductos {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 26.5%;
    padding-bottom: 140px;
}

.BoxProducto {
    display: flex;
    align-items: center;
    flex-direction: column;
    min-height: 170px;
    cursor: pointer;
}

.BoxProducto label {
    width: 100%;
    font-size: 16px;
    width: 65%;
    margin-bottom: 0;
    font-family: AvenirBook;
    text-align: center;
    cursor: pointer;
}

.BoxInfoProducto {
    width: 100%;
    height: 100%;
    padding-right: 13px;
    padding-left: 51px;
}

.DivButtonPreguntas button {
    outline: none;
    border: #ffffff solid 3px;
    background: #de0034 0% 0% no-repeat padding-box;
    border-radius: 16px;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-transform: uppercase;
    font-family: CocogooseLight;
    font-weight: bold;
    font-size: 12px;
    padding: 2% 16%;
    margin-left: 11%;
    margin-top: 54px;
}

.DivButtonPreguntas button:focus {
    outline: none;
}

.DivButtonPreguntas button:hover {
    background: transparent;
    border: 3px solid #de0034;
    color: #de0034;
}

.DivImagenes {
    position: relative;
    width: 65%;
    float: left;
}

.BoxTxtDesripTea {
    position: absolute;
    width: 90%;
    top: -155px;
    right: 22px;
    padding: 11% 13% 14% 26%;

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.BoxTxtDesripTea img {
    cursor: pointer;
    position: absolute;
    width: 20px;
    height: 20px;
    top: -6%;
    right: 1%;
}

.BoxTxtDesripTea label {
    font-family: AvenirBlack;
}

.BoxTxtDesripTea text {
    font-family: AvenirBook;
}

.ContainerInformacionDetox {
    position: absolute;
    width: 96%;
    top: 7.5%;
    left: 2%;
    background: #FDFDF3 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    overflow: hidden;
    transition: height 0.5s ease, opacity 0.2s ease, margin 0.5s ease;
}

.ContainerInformacionDetox label {
    display: flex;
    align-items: center;
    font-family: CocogooseProRegular;
    text-transform: uppercase;
    letter-spacing: 0px;
    color: #0A0A0A;
    cursor: pointer;
    font-size: 30px;
}

.BoxInformacionDetox {
    width: 94%;
    height: 90%;
    margin: 3%;
    position: relative;
}

.BoxInfoProductoDesk,
.FloatingDescriptionDesk {
    display: inline;
}

.BoxInfoProductoIpad,
.FloatingDescriptionIpad {
    display: none;
}

.BoxInfoProductoMobile,
.FloatingDescriptionMobile {
    display: none;
}

.IconTePopup {
    width: 29px;
    height: 30px;
    margin-right: 17px;
}

.Container,
.ContainerBanner {
    padding: 19px 26px;
}

.ContainerBanner {
    padding-bottom: 0;
}

.IconCerrarPopupDetox {
    cursor: pointer;
    position: absolute;
    right: 18px;
    top: 19px;
}

.BoxPreguntas {
    width: 28%;
    float: left;
    height: 100%;
    position: relative;
    padding-top: 5.5%;
}

.ImagenDiapCSS {
    margin-bottom: 67px;
}

.BoxTexto {
    width: 72%;
    float: left;
    height: 100%;
    letter-spacing: 0px;
    color: #000000;
    font-family: AvenirBook;
    font-size: 23px;
    padding: 3% 1% 3% 3%;
    transition: opacity 0.2s ease;
}

.EspaciadorBanner {
    height: 20px;
    width: 100%;
    display: inline-block;
}

.ContainerBanner img {
    width: 100%;
    height: 698px;
}

.BoxProducto img {
    width: 150px;
}

.DivPreguntas {
    width: 35%;
    float: left;
    display: flex;
    flex-direction: column;
}

.DivPreguntas label {
    font-size: 30px;
    margin-bottom: 45px;
    font-family: CocogooseProRegular;
    text-transform: uppercase;
    letter-spacing: 0px;
    color: #0A0A0A;
    cursor: pointer;
}

.DivPreguntas img {
    width: 29px;
    height: 30px;
    margin-right: 17px;
}

.DivImgProducto {
    width: 80%;
    position: absolute;
    right: 0;
    top: 120px;
}

.DivImgProductoImg {
    width: 650px;
    cursor: pointer;
}

.DivDescripcionTe {
    background: #FDFDF3 0% 0% no-repeat padding-box;
    border-radius: 20px;
    letter-spacing: 0px;
    color: #000000;
    position: absolute;
    top: -200px;
    right: 0%;
    width: 92%;
    height: 380px;
}

.ImgAbs {
    position: absolute;
    left: -62px;
    top: 33px;
    max-width: 415px;
}

.ImgAbs img {
    max-width: 97%;
}

@media screen and (max-width: 1024px) {
    /* Ipad */
    .BoxInfoProductoDesk,
    .FloatingDescriptionDesk {
        display: none;
    }

    .BoxInfoProductoIpad,
    .FloatingDescriptionIpad {
        display: inline;
    }

    .BoxInfoProductoMobile,
    .FloatingDescriptionMobile {
        display: none;
    }

    .BoxMenuProductos {
        padding: 2% 12.5% 32px;
    }

    .DivPreguntas {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-left: 26%;
        margin-top: 16px;
    }

    .DivPreguntas label {
        font-size: 45px;
        margin-bottom: 15px;
    }

    .DivPreguntas img {
        width: 40px;
        height: 40px;
        margin-right: 14px;
    }

    .Container {
        padding: 3% 2%;
    }

    .BoxTexto,
    .BoxPreguntas {
        width: 100%;
        height: auto;
    }

    .BoxTexto { padding: 3% 2%; }
    .BoxPreguntas { padding-top: 0;}

    .DivImgTitInfDetox {
        text-align: center;
        margin-bottom: 10px;
        margin-top: 8%;
    }

    .DivImgTitInfDetox img {
        width: 60%;
        max-height: 102px;
    }

    .IconTePopup {
        width: 40px;
        height: 40px;
        margin-right: 15px;
    }

    .EspaciadorBanner {
        height: 10vw;
        width: 100%;
        display: inline-block;
    }

    .ContainerInformacionDetox {
        position: relative;
        width: 96%;
    }

    .ContainerInformacionDetox label {
        font-size: 45px;
    }

    .ImgPlanDetox2 {
        width: 50%;
    }

    .ImagenDiapCSS {
        margin-bottom: 0;
    }

    .ContainerBanner {
        padding: 0;
    }

    .ContainerBanner img {
        width: 100%;
        height: 470px;
    }

    .ContainerMenuDiapMob {
        padding-left: 25%;
        margin-top: 24px;
    }

    .IconCerrarPopupDetox {
        cursor: pointer;
        position: absolute;
        right: 30px;
        margin-top: 1.5%;
        width: 24px;
        z-index: 2;
    }

    .TitleSection {
        text-align: left;
        font-size: 35px;
        line-height: 1.15;
    }

    .BoxProducto {
        min-height: 93px;
    }

    .BoxProducto img {
        width: 150px;
    }

    .BoxProducto label {
        width: 100%;
        margin-bottom: 0;
    }

    .BoxTexto {
        font-size: 26px;
    }

    .BoxInfoProducto {
        padding: 0;
        padding-top: 0;
    }

    .DivImagenes {
        width: 100%;
    }

    .DivButtonPreguntas button {
        font-size: 26px;
        border-radius: 40px;
        padding: 2.2% 16%;
        margin-top: 5%;
        margin-left: 7%;
    }

    .DivImgProducto {
        position: relative;
        left: 11%;
        top: 0;
    }

    .DivImgProductoImg {
        width: 100%;
        height: auto;
    }

    .ImgAbs {
        max-width: 400px;
        position: absolute;
        left: -73px;
        top: 35%;
    }

    .ImgAbs img {
        width: 95%;
    }

    .BoxTxtDesripTea {
        width: 80%;
        position: absolute;
        height: 70%;
        top: 14%;
        right: 10%;
        padding: 7%;
        font-size: 26px;
    }

    .BoxTxtDesripTea img {
        top: 20px;
        right: 18px;
    }

    .DivDescripcionTe {
        background: #FDFDF3 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 12px;
        height: 100%;
        letter-spacing: 0px;
        color: #000000;
        position: absolute;
        top: 14%;
        right: 10%;
        width: 80%;
        height: 70%;
    }
}

@media screen and (max-width: 600px),
(max-height: 325px) {

    /* Mobile */
    .BoxInfoProductoDesk,
    .FloatingDescriptionDesk {
        display: none;
    }

    .BoxInfoProductoIpad,
    .FloatingDescriptionIpad {
        display: none;
    }

    .BoxInfoProductoMobile,
    .FloatingDescriptionMobile {
        display: inline;
    }

    .BoxMenuProductos {
        padding: 0 30px;
    }

    .ContainerBanner img {
        height: 201px;
    }

    .BoxProducto img {
        width: 72px;
    }

    .ImgAbs {
        max-width: 129px;
        left: -15px;
        top: 49%;
    }

    .Container {
        padding: 7px 9px;
    }

    .BoxProducto label {
        font-size: 10px;
    }

    .BoxTxtDesripTea {
        font-size: 12px;
    }

    .BoxTxtDesripTea img {
        width: 10px;
        height: 10px;
        top: 16px;
        right: 14px;
    }

    .DivPreguntas {
        padding-left: 21%;
    }

    .DivPreguntas label {
        font-size: 19px;
    }

    .DivPreguntas img {
        width: 20px;
        height: 20px;
    }

    .DivButtonPreguntas button {
        border-radius: 16px;
        font-size: 10px;
        margin-left: 10%;
    }

    .BoxTexto {
        font-size: 12px;
    }

    .DivImgTitInfDetox img {
        max-height: 52px;
        width: 75%;
    }

    .IconCerrarPopupDetox {
        width: 9px;
        right: 12px;
        margin-top: -2.5%;
    }

    .ContainerInformacionDetox label {
        font-size: 19px;
    }

    .IconTePopup {
        width: 20px;
        height: 20px;
    }

    .ContainerMenuDiapMob {
        padding-left: 20%;
    }
}