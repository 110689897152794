.container-found-page{
  width:100%;
  height:100vh;

  
  font-family: JustTellMeWhat;
  display: flex;
  align-items: center;
  color: #001a44;
}
.container-found-page-mobile{
  display: none;
}


.No-encontramos-tu-s {
 
  font-family:CocogooseProRegular;
  font-size: 1.2vw;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.24;
  letter-spacing: 2px;
  color: #000000;
}
.Error-404 {
  
  font-family: CocogooseProRegular;
  font-size: 2.3vw;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.24;
  letter-spacing: 2px;
  color: #a4a4a4;
}
.div-image{
  width:50%;
  display:flex;
  justify-content:flex-end;
  align-items:center;
}
.found-page{
  width: 100%; 
  font-size: 2vw; 
  display: flex;
}
.texto-container{
  width:50%;
  padding:20px
}
.texto-info{
  font-size: 1.2vw
}
@media screen and (max-width: 687px), (max-height: 325px) {
  .container-found-page{
    display: none;
  }
  .texto-info{
    font-size: 15px
  }
  .texto-container{
    width:100%;
  }
  .div-image{
    width:100%;
    display:flex;
    justify-content:center;
    align-items:center;
  }
  .found-page{
   
    display: flex;
    flex-direction: column;
  }
  .No-encontramos-tu-s {
    font-size: 25px;
  }
  .Error-404 {

    font-size: 25px;
   
  }
}