


.btn-detox{
  border-radius: 10px;
  background: #2D8ED0;
  color: #ffffff;
  padding-left: 30px;
  padding-right: 30px;
  border: 0px;
}
.cont-btn-detox{
      /* font-family: CocogooseProRegular; */
      width: 60%;
      z-index: 10;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      font-family: CocogooseLight;
      top: 80%;
      display: flex;
    justify-content: center;
      left: 50%;
      position: absolute;
      text-align: center;
}
.Especiales {
    width: 763px;
    height: 45px;
    font-family: CocogooseProRegular;
    font-size: 32px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.41;
    letter-spacing: -0.76px;
    text-align: center;
    color: #8d9495;
   
  }

  .btn-detalle-producto{
    background: #ffffff;
    border: 3px solid #ffbd00;
    color: black;
   
    border-radius: 15px;
    font-family:AvenirBlack;
    padding-left: 15px;
    padding-right: 15px;
    transition: 0.5s;
    margin: 5px;

}
.btn-detalle-producto:hover{
  border: 3px solid #ffbd00;
  color: black;
  background: #ffbd00;
}
.filtro-productos-detalle{
  font-family:AvenirBook;
  background: #ffffff;
  background: #ffffff;
  box-shadow: 2px 2px 10px #666;  
  position: relative;
  transform: translate(-50%, -50%);
  margin-top:-6%;
  left: 50%;
  z-index: 2;
  width: 80%;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px
}

.detalle-productos-desk  {
  display: block
  }
.detalle-productos-mobile   {
  display: none
  }
  
  


@media screen and (max-width: 687px), (max-height: 325px) {
  .btn-detox{
 
    font-size: 13px;
    padding-left: 2px;
    padding-right: 2px;
    border: 0px;
  }
  .cont-btn-detox{
        /* font-family: CocogooseProRegular; */
        width:55%;
        z-index: 10;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        top: 91%;
        display: flex;
      flex-direction: column;
      justify-content: flex-start;
        left: 34%;
        position: absolute;
        text-align: center;
  }

  .detalle-productos-desk{
     display: none
    }

  .detalle-productos-mobile{
      display: block
    } 
    
    

  .filtro-productos-detalle{
    font-family:AvenirBook;
    background: #ffffff;
    background: #ffffff;
    box-shadow: 2px 2px 10px #666;  
    position: relative;
    transform: translate(-50%, -50%);
    margin-top:-6%;
    left: 50%;
    z-index: 2;
    width: 93%;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px
  }



}