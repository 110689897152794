.productos-desk-tips{
    /* padding-left: 5%;
    padding-right: 5%; */
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column

    
}
.cont-recetas-img{
    width:100%;
    display:flex;
    flex-wrap:wrap;
    justify-content: space-evenly;
}
.recetas-img{
    max-width: 20%;
}
.productos-mobile-tips{
    position: relative;
}
.banner-tips{
    width: 100%;
    height: auto
}
.title-tips{
    font-size: 3.1vw;
    font-weight: normal;
}
.tips-container{
    padding-left: 5%;
    padding-right: 5%; 
    font-size: 1.5vw; 
    margin-top: 4%;
}
.container-swiper-tips{
    width: 100%; 
    display: flex; 
    justify-content: center; 
    padding-left: 30px;
    padding-right: 30px
}
.swiper-productos-mobile-tips{
    width: 80%
}
.contain-recetas {
    margin-top: 20px;
}
.contain-recetas p{
    margin: 0;
}
@media screen and (max-width: 687px), (max-height: 325px) {
    .banner-tips{
        width: 100%;
        height: 136px
    }
    .container-swiper-tips{
        padding-left: 5px;
        padding-right: 5px   
    }
    .tips-container{
        padding-left: 10px;
        padding-right: 10px; 
        font-size: 16px
    }
    .title-tips{
        font-size: 20px;
        font-weight: normal;
    }
   
    .productos-desk-tips{
      
        display: none;
    }
    .productos-mobile-tips{
        display: block;
        margin-top: 5%;
    }
}