

.child-container{
    height: calc( 100vh - 60px ) ;
    top: 60px;
    width: 100%;
    overflow: none;
    background: white;
    position: fixed;
    z-index: 29;
    /*visibility: hidden;*/

}

.close-button-modal{
    position: absolute;
    height: 28px;
    top: 15px; 
    right: 29px;
}

.header-menu {
    height: 60px;
    top: 0px;
    width: 100%;
    background-color: white;
    z-index: 31;
    position: fixed;
}
.header-menu-text{
    position: absolute;
    font-family: BarlowRegular;
    color: black;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 100%;
    letter-spacing: normal;
    top: 18px;
    margin-left: 4.3%;
    height: 100%;
    text-align: center;
}


@media only screen and (max-width:700px){
    .child-container{
        top: 49px;
        height: calc( 100vh - 49px ) ;
    }
    .header-menu {
        height: 49px;
    }
    .header-menu-text{
       top: 14px 
    }
    .close-button-modal{
    top: 10px; 
    right: 5px;
    }

}