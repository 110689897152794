.btn-recetas{
    border: 1px solid #ffffff;
    color: #ffffff;
    background: transparent;
    border-radius: 15px;
    font-family:AvenirBlack;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 17px;
    transition: 0.3s;
}

.zoom:hover {
  -ms-transform: scale(1.5); /* IE 9 */
  -webkit-transform: scale(1.5); /* Safari 3-8 */
  transform: scale(1.5); 
}
  .banner-recetas-button-desk{
   position: absolute;
   margin-top: 7%;
   top: 50% !important;
   text-align: center;
   width: 100%;  
  }


.btn-recetas:hover{
    background: #ffbd00;
    color: black;
    border: 1px solid #ffbd00;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.28);
    /*-ms-transform: scale(1.15); /* IE 9 
    -webkit-transform: scale(1.15); /* 
    transform: scale(1.15);*/ 
}
.btn-receta-static{
    margin-right: 20px;
    border-radius: 15px;
    font-family:AvenirBlack;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 17px;
    background: #ffbd00;
    color: black;
    border: 1px solid #ffbd00;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.28);
}
.swiper-container{
    background: #ffffff !important;
}
.MS-POPULARES {
 
    font-family: AvenirBook;
    font-size: 16px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.88;
    letter-spacing: 2.5px;
    color: #d41245;
  }
  .LAS-RECETAS-MS-POPU {
   
    font-family:CocogooseProRegular;
    font-size: 42px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: normal;
    color: #000000;
  }
.filtro-recetas{
    background: #ffffff;
    background: #ffffff;
    box-shadow: 2px 2px 10px #666;  
    position: relative;
    transform: translate(-50%, 14%);
    margin-top: -6%;
    left: 50%;
    width: 88%;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    z-index: 1;


}
.UN-SINFN-DE-RECETAS {
  position: absolute;
  -webkit-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  left: 50%;
  width: 90%;
  top:40%;
 
    text-shadow: 4px 8px 16px rgba(0, 0, 0, 0.59);
    font-family:CocogooseProRegular;
    font-size: 71px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: 2.14px;
    text-align: center;
    color: #ffffff;
  }

.select-recetas{
    border: 4px solid #ffd766;
    width: 20%;
    height: auto;
    text-align: center;
    font-family: AvenirBook;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 3.29;
    letter-spacing: 2.19px;
    color: #000000;
}
.recetas-mobile{
  display: none
}
.recetas-desk{
  display: block;

}

@media screen and (max-width: 687px), (max-height: 325px) {
  .recetas-mobile{
    display: block
  }

  .recetas-desk{
    display: none;
    margin-top: 10px;
  }

  .UN-SINFN-DE-RECETAS {
    position: relative;
    font-size: 40px;
    width: 100%;
    text-align: center;
    top: 38% ;
    left: 0%;
    transform: none;
    letter-spacing: .95px;
    line-height: 41px;
    margin-bottom: 12px;
  }

  .banner-recetas-button-desk{
   display: none;
  }

  /* Inicio de ajustes para Filtrar por mobile */
  .div-filtrar-por{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -58px;
  }

  .divSon-filtrar-por{
    background: rgb(255, 255, 255);
    width: 96%;
    height: 59px;
    padding-left: 21px;
    padding-right: 21px;
    padding-top: 10px;
    padding-bottom: 7px;
    z-index: 2;
    box-shadow: -3px 16px 26px rgba(0, 0, 0, 0.18);
  }

  /* Ajustes de diseño para FILTRAR POR en ExpansionPanel */
  .MuiExpansionPanelSummary-root {
    display: flex;
    padding: 5px 24px 5px 24px !important;
    min-height: 38px !important;
    height: 38px;
    transition: min-height 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  /* Quitar sombra de filtrar por */
  .MuiPaper-elevation1 {
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0), 0px 0px 0px 0px rgba(0,0,0,0), 0px 1px 3px 0px rgba(0,0,0,0) !important;
  }

  /* Personalización de texto para FILTRAR POR en ExpansionPanel */
  .MuiTypography-body1 {
    font-size: .8rem !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500 !important;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    text-align: left;
    width: 100% !important;
  }

  /* margen de sub-sub-menu*/
  .MuiExpansionPanelDetails-root {
    display: flex;
    padding: 8px 24px 12px !important;
  }

  .MuiIconButton-edgeEnd {
    margin-right: -6px !important;
  }

  .css-1okebmr-indicatorSeparator {
      width: 0 !important;
  }

  .css-tlfecz-indicatorContainer {
    padding: 8px !important;
  }
  
  .css-1wa3eu0-placeholder {
    color: black !important;
  }

  .css-tlfecz-indicatorContainer {
    color: hsl(0,0%,50%) !important;}

  /* Fin de ajustes de diseño de Filtrar por mobile*/

  .tam-img{
    width: 100%;
    height: 392px;
    object-fit: cover;
    object-position: 50% 75%;
  }

  .tam-iconoX{
    width: 16px;
    height: 16px;
    position: absolute;
    top: 16px;
    right: 30px;
    z-index: 2;
  }

  .SABOR-TRADICIONAL{
    font-family: SignPainter;
    font-size: 20px;
    width: 100%;
    height: 44px;
    font-weight: lighter;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.15;
    letter-spacing: 1.2px;
    text-align: center;
    color: #ffffff;
    margin-bottom: 21px;
  }

  .BTN-TODAS{
    background: #ffbd00;
    border: 1px solid #ffbd00;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.28);
    color: #000;
    border-radius: 16px;
    font-family: AvenirBlack;
    font-size: 14px;
    width: 67.5%;
    height: 32px;
    cursor: pointer;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .BTN-TODAS2{
    background: #ffbd00;
    color: #000;
    border: 1px solid #ffbd00;
    border-radius: 16px;
    font-family: AvenirBlack;
    font-size: 14px;
    width: auto;
    height: 32px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 25px;
    padding-right: 25px;
    margin-right: 15px;
  }


  .RECETAS-BANNER{
    width: 100%;
    transform: translate(-50% ,-50%);
    position: absolute;
    top: 45%;
    left: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .RECETAS-BANNER2 {
    display: flex;
    width: 100%;
    position: absolute;
    left: 0;
    top: 8vh;
    justify-content: center;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
    min-width: 284px;
    grid-gap: 2% ;
  }
  .RECETAS-BANNER2 > button {
    margin-bottom: 7px;
    text-align: center;
   }

  .btn-recetas {
    border: 1px solid #ffffff;
    color: #ffffff;
    background: transparent;
    border-radius: 16px;
    height: 32px;
    font-family: AvenirBlack;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 17px;

  }

  .btn-recetas:hover {
    background: #ffbd00;
    border: 1px solid #ffbd00;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.28);
    color: white;
    /*transform: scale(1.15);*/
  }
  
  .MS-POPULARES {
   
    font-family: AvenirBlack;
    font-size: 10px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    color: #d41245;
  }

  .LAS-RECETAS-MS-POPU {
    
    font-family:CocogooseProRegular;
    font-size: 22px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: normal;
    color: #000000;
  }

  .btn-cargar-mas{
    /* padding-left: 40px;
    padding-right: 40px;
    font-family: AvenirBlack;
    margin-top: 10px;
    border: 3px solid #d41246;
    border-radius: 15px;
    transition: 1.0s;
    background: #d41246;
    color: #FFFFFF;
    cursor: pointer; */
    padding-left: 40px;
    padding-right: 40px;
    font-family: AvenirBlack;
    margin-top: 10px;
    border-radius: 15px;
    transition: 1.0s;
    background: transparent;
    border: 3px solid #bfbfbf;
    color: #bfbfbf;
    cursor: pointer;
  }
  
}

@media screen and (max-width: 1024px), (max-height: 325px) {
 
}
